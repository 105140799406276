import React from 'react'
import { Button } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";

export const TableOperationsColumn = ({
  record,
  onCancel,
  onSave,
  onEdit,
  onDelete,
  isEditing,
  disabled,
}) => {
  return (
    <div className="actions" onClick={(event) => event.stopPropagation()}>
      {isEditing ? (
       <>
         <Button className="uppercase" size="small" type="link" onClick={onCancel}>
           Cancel
         </Button>
         <Button size="small" type="link" className="uppercase pl-4" onClick={() => onSave(record)}>
           Save
         </Button>
       </>
      ) : (
        <>
          <Button
            size="small"
            className="uppercase"
            type="link"
            disabled={disabled}
            onClick={() => {
              onEdit(record);
            }}
          >
            Quick Edit
          </Button>
          <Button
            size="small"
            className="delete-btn"
            type="link"
            danger
            disabled={disabled}
            onClick={() => onDelete(record)}
          >
            <DeleteTwoTone twoToneColor="#eb2f96" />
          </Button>
        </>
      )}
    </div>
  );
};
