import React, { useState, useMemo } from "react";
import { SearchInput } from "src/components/inputs/SearchInput";
import { Select } from "antd";
const Option = Select.Option;

export const InputSelectSearch = ({ items, placeholder, onChange, value }) => {
  const [search, setSearch] = useState("");

  const filteredItems = useMemo(() => {
    return items.filter((item) => item.key.includes(search));
  }, [items, search]);

  return (
    <Select
      value={value}
      // defaultValue={value}
      onChange={onChange}
      dropdownRender={(menu) => (
        <div className="pl-2 pr-2">
          <SearchInput
            className="mb-4"
            placeholder={placeholder}
            onChangeText={setSearch}
          />
          {menu}
        </div>
      )}
    >
      {filteredItems &&
        filteredItems.map((option) => {
          return (
            <Option value={option.key} key={option.key}>
              {option.value}
            </Option>
          );
        })}
    </Select>
  );
};
