import React, { useEffect, useMemo, useState } from "react";
import { Menu, Layout, Input } from "antd";
import {
  SearchOutlined,
  BookOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  UploadOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "src/modules/auth/provider/auth.provider";
import { Routes } from "src/router/Routes.helper";

const MenuItem = ({ to, children, ...rest }) => {
  const location = useLocation();

  useEffect(() => {
    const isActive = location.pathname.startsWith(to);
    const link = document.querySelector(`[href="${to}"]`);
    if (!link) return;
    const li = link.closest("li");
    if (!li) return;
    isActive
      ? li.classList.add("ant-menu-item-active", "ant-menu-item-selected", "hover")
      : li.classList.remove("ant-menu-item-active", "ant-menu-item-selected");
  }, [location.pathname, to]);

  return (
    <Menu.Item  {...rest}>
      <Link
        to={to}
        className={to === location.pathname ? "pointer-events-none" : ""}
      >
        {children}
      </Link>
    </Menu.Item>
  );
};

export const SideBar = () => {
  const location = useLocation();
  const history = useHistory();
  const { signOut } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const onSearch = (product) => {
    history.push({
      pathname: Routes.PATHS.PRODUCTS,
      search: `text=${product}`,
    });
  };

  const menuItems = [
    {
      title: "Home",
      path: Routes.PATHS.DASHBOARD,
      icon: <HomeOutlined />,
    },
    {
      title: "Catalog",
      key: "catalog",
      icon: <BookOutlined />,
      children: [
        {
          title: "Products",
          path: Routes.PATHS.PRODUCTS,
        },
        {
          title: "Visual merchandising",
          path: Routes.PATHS.VISUAL_MERCHANDISING,
        },
      ],
    },
    {
      title: "Warehouse",
      path: Routes.PATHS.WAREHOUSE,
      icon: <ShoppingCartOutlined />,
    },
    {
      title: "Upload",
      path: Routes.PATHS.UPLOADS_LIST,
      icon: <UploadOutlined />,
    },
  ];

  const openedMenuItems = useMemo(() => {
    const result = menuItems.find(({ children }) => {
      if (!children) return false;
      return children.find(({ path }) => path === location.pathname);
    });
    return result ? [result.key] : [];
  }, [location.pathname, menuItems]);

  const renderMenuItem = (item) => (
    <MenuItem key={item.path} to={item.path} icon={item.icon}>
      {item.title}
    </MenuItem>
  );

  const renderMenuItems = () => {
    return menuItems.map((item) => {
      if (!item.children) {
        return renderMenuItem(item);
      }
      return (
        <Menu.SubMenu key={item.key} icon={item.icon} title={item.title}>
          {item.children.map((subItem) => renderMenuItem(subItem))}
        </Menu.SubMenu>
      );
    });
  };

  return (
    <Layout.Sider
      width={250}
      theme="light"
      className="pt-6"
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
    >
      <div className="mb-10">
        <img
          src="https://stentle.com/wp-content/themes/stentle/dist/images/logo-stentle-az.png"
          alt="Stentle"
        />
      </div>
      {/*TODO Uncomment in later*/}
      {collapsed &&
        < div className="mb-2 px-8">
          <SearchOutlined onClick={() => setCollapsed(false)} />
        </div>
      }
      {
        !collapsed &&
        < div className="mb-6 px-4">
          <Input.Search placeholder="Search a product" onSearch={onSearch} />
        </div>
      }
      <Menu
        className="main-menu"
        defaultSelectedKeys={[Routes.PATHS.DASHBOARD]}
        defaultOpenKeys={openedMenuItems}
        selectedKeys={[location.pathname]}
        mode="inline"
      >
        {renderMenuItems()}
      </Menu>
      <Menu mode="inline">
        <Menu.Item onClick={signOut} key="6" icon={<LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu>
    </Layout.Sider >
  );
};
