import React, { useState, useMemo, useCallback } from "react";
import { Table, Form } from "antd";
import { useUndoNotification } from "src/hooks/useUndoNotification";
import { EditableCell } from "src/modules/products/components/EditableCell";
import { TableOperationsColumn } from "src/modules/products/components/TableOperationsColumn";
import { PlaceholderService } from "src/services/Placeholder.service";
import { useLatestRef } from "src/hooks/useLatestRef";
import { useUnloadEffect } from "src/hooks/useUnloadEffect";
import { useTableHeight } from "src/hooks/useTableHeight";

export const EditableTable = ({
  onDelete,
  onUpdateRecord,
  deleteMessage,
  tableData,
  pagination,
  onTableChange,
  columns,
  onRowClick,
  loading,
  onStartEdit,
  scroll,
}) => {
  const onUpdateRecordRef = useLatestRef(onUpdateRecord);
  const [form] = Form.useForm();
  const [editingRecordId, setEditingRecordId] = useState(null);
  const openUndoNotification = useUndoNotification(3000, onDelete, {});
  const [selectedRecord, setSelectedRecord] = useState();
  const [isRecordDeleted, setIsRecordDeleted] = useState(false);
  const tableHeight = useTableHeight();

  // Check if a record is in editing mode or not
  const isEditingRecord = useCallback(
    (record) => {
      return record.id === editingRecordId;
    },
    [editingRecordId]
  );

  // Check if user has already start to edit a record
  const isEditingModeEnabled = useMemo(() => {
    return !!editingRecordId;
  }, [editingRecordId]);

  // Logic to save a single record
  const onSaveRecord = useCallback(
    async (record) => {
      const formValues = await form.validateFields();
      setEditingRecordId(null);
      onUpdateRecordRef.current(formValues, record);
    },
    [form, onUpdateRecordRef]
  );

  const onStartEditing = useCallback(
    (record) => {
      form.setFieldsValue({ ...record });
      setEditingRecordId(record.id);
      onStartEdit(record.id);
    },
    [form]
  );

  const onCancel = () => {
    setSelectedRecord(null);
    setEditingRecordId(null);
  };

  const handleDelete = useCallback(
    (record) => {
      setIsRecordDeleted(true);
      setSelectedRecord(record);
      openUndoNotification(record, {
        message: PlaceholderService.getMessage(record, deleteMessage),
      });
    },
    [openUndoNotification, deleteMessage]
  );

  const handleRowClick = (record) => {
    if (!editingRecordId && onRowClick) {
      onRowClick(record);
    }
  };

  useUnloadEffect(() => {
    if (isRecordDeleted) {
      onDelete(selectedRecord);
    }
  });
  const operationColumn = useMemo(
    () => ({
      title: "",
      dataIndex: "operation",
      width: 200,
      render: (_, record) => {
        return (
          <TableOperationsColumn
            record={record}
            onCancel={onCancel}
            onSave={onSaveRecord}
            onEdit={onStartEditing}
            onDelete={handleDelete}
            isEditing={isEditingRecord(record)}
            disabled={isEditingModeEnabled}
          />
        );
      },
    }),
    [
      isEditingModeEnabled,
      isEditingRecord,
      onStartEditing,
      handleDelete,
      onSaveRecord,
    ]
  );

  const enrichedColumns = useMemo(() => {
    // Adding editable column props
    const columnsDraft = columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.inputType,
          dataIndex: col.dataIndex,
          inputOptions: col.inputOptions,
          title: col.title,
          editing: isEditingRecord(record),
          onLoadMore: col.onLoadMore,
          onChange: col.onChange
            ? (...args) => col.onChange(...args, form)
            : undefined,
          onMount: col.onMount,
          placeholder: col.placeholder,
        }),
      };
    });

    // Adding operation column
    return [...columnsDraft, operationColumn];
  }, [columns, operationColumn, isEditingRecord, form]);

  return (
    <Form form={form} component={false}>
      <Table
        size="middle"
        className={handleRowClick ? "clickable" : ""}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => handleRowClick(record),
          };
        }}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        sticky={true}
        scroll={{ y: tableHeight }}
        rowKey="id"
        loading={loading}
        dataSource={tableData}
        columns={enrichedColumns}
        pagination={pagination}
        onChange={onTableChange}
        rowClassName={(record) => (record.isUpdatePending ? "opacity-50" : "")}
      />
    </Form>
  );
};
