import {UploadTypes} from "src/modules/upload/domain/models/UploadTypes";
import { UPLOAD_FILE_TYPE_TITLE } from 'src/constants/uploadFileTypeTitle'
const { TYPE_KEYS } = UploadTypes;

export const UPLOAD_TYPES = [
    {
        id: 1,
        title: UPLOAD_FILE_TYPE_TITLE.STOCK,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper nullam amet.",
        avatar: 'image.jpg',
        formats: ['csv'],
        max_wight: 300,
        template_link: "sample.template.csv",
        disabled: false,
        type_key: TYPE_KEYS.STOCK
    },
    {
        id: 2,
        title: UPLOAD_FILE_TYPE_TITLE.PRODUCT,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper nullam amet.",
        avatar: 'image.jpg',
        formats: ['csv'],
        max_wight: 300,
        template_link: "sample.template.csv",
        disabled: false,
        type_key: TYPE_KEYS.PRODUCT
    },
    {
        id: 3,
        title: UPLOAD_FILE_TYPE_TITLE.CATEGORY,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper nullam amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper nullam amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper nullam amet.",
        avatar: 'image.jpg',
        formats: ['csv'],
        max_wight: 300,
        template_link: "sample.template.csv",
        disabled: false,
        type_key: TYPE_KEYS.CATEGORY
    },
    {
        id: 4,
        title: UPLOAD_FILE_TYPE_TITLE.ATTRIBUTE,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper nullam amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper nullam amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper nullam amet.",
        avatar: 'image.jpg',
        formats: ['csv'],
        max_wight: 300,
        template_link: "sample.template.csv",
        disabled: false,
        type_key: TYPE_KEYS.ATTRIBUTE
    }
]
