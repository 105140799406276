import React from 'react'
import { Switch, Route, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AuthProvider } from "src/modules/auth/provider/auth.provider";
import { PageLogin } from "src/modules/auth/pages/PageLogin";
import { PrivateRoute } from "src/router/PrivateRoute";
import { AppLayout } from "src/layouts/AppLayout";
import { Routes } from "src/router/Routes.helper";

// styles
import "./styles/tailwind.css";
import "antd/dist/antd.css";
import "./styles/index.css";
import { LanguageProvider } from "./modules/global/provider/languages.provider";

export const history = createBrowserHistory();

function App() {
  return (
    <AuthProvider>
      <LanguageProvider>
        <Router history={history}>
          <Switch>
            <Route path={Routes.PATHS.LOGIN}>
              <PageLogin />
            </Route>
            <PrivateRoute path="/">
              <AppLayout />
            </PrivateRoute>
          </Switch>
        </Router>
      </LanguageProvider>
    </AuthProvider>
  );
}

export default App;
