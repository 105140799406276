export class TranslatedFields {
    static translatedField(data, product, translatedFields, defLanguage) {
        translatedFields.forEach((field) => {
            const [fieldKey] = Object.keys(field);
            const fieldValue = field[fieldKey];

            data[fieldKey] = {
                ...product[fieldKey],
                [defLanguage]: { value: data[fieldValue], locale: defLanguage },
            };
        });
    }
}
