import { UploadTypes } from "src/modules/upload/domain/models/UploadTypes";
const { TYPE_KEYS } = UploadTypes;

export const SAMPLE_TEMPLATE_LINKS = {
    [TYPE_KEYS.ATTRIBUTE]: "https://stentle-image.s3.eu-west-1.amazonaws.com/template-csv/csv-template-attributes.csv",
    [TYPE_KEYS.CATEGORY]: "https://stentle-image.s3.eu-west-1.amazonaws.com/template-csv/csv-template-categories.csv",
    [TYPE_KEYS.PRODUCT]: "https://stentle-image.s3.eu-west-1.amazonaws.com/template-csv/csv-template-products.csv",
    [TYPE_KEYS.STOCK]: "https://stentle-image.s3.eu-west-1.amazonaws.com/template-csv/csv-template-stocks.csv"
}


