import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { GlobalAPI } from "src/modules/global/api/GlobalAPI";
import { LANGUAGES } from "src/constants/languages";

export const languagesContext = createContext();

const useProvideLanguages = () => {
  const [languages, setLanguages] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState([]);
  const getLanguages = useCallback(async () => {
    try {
      const res = await GlobalAPI.getLanguages();
      const languageData = res.data.data.map((item) => ({
        ...item,
        label: LANGUAGES[item.language],
      }));
      setLanguages(languageData);
      return Promise.resolve();
    } catch (e) {
      // @todo handle notification
      return Promise.reject(e);
    }
  }, []);

  const getDefaultLanguage = useCallback(() => {
    const dfLanguage = languages.find(({ defaultLanguage }) => !!defaultLanguage) || { language: "it", defaultLanguage: true, label: "Italian" };
    setDefaultLanguage(dfLanguage)
  }, [languages])

  // const defaultLanguage = useMemo(() => {
  //   return languages.find(({ defaultLanguage }) => !!defaultLanguage) || { language: "en", defaultLanguage: true, label: "English" };
  // }, [languages]);

  useEffect(() => {
    getDefaultLanguage();
    getLanguages();
  }, []);

  return { languages, defaultLanguage, setDefaultLanguage };
};

export const LanguageProvider = ({ children }) => {
  const languages = useProvideLanguages();

  return (
      <languagesContext.Provider value={languages}>
        {children}
      </languagesContext.Provider>
  );
};

export const useLanguages = () => useContext(languagesContext);
