import React, { useState, createContext, useCallback, useEffect, useContext } from 'react'
import { message } from "src/services/Messages.service";
import { useLocation } from "react-router-dom";
import { VisualMerchandisingAPI } from "../api/VisualMerchandisingApi";

export const visualMerchandisingContext = createContext();
const useProvideVisualMerchandising = () => {
    const location = useLocation()
    const [visualMerchandisingList, setVisualMerchandisingList] = useState([])
    const [isListLoading, setIsListLoading] = useState(false)
    const [filteredVisualMerchandisingList, setFilteredVisualMerchandisingList] = useState([])

    /**
     * @description Get visual merchandising list
     * @type {(function(): Promise<void|undefined>)|*}
     */
    const getVisualMerchandisingList = useCallback(async () => {
        setIsListLoading(true)
        try {
            const res = await VisualMerchandisingAPI.getVisualMerchandisingList()
            setVisualMerchandisingList(res.data);
            setFilteredVisualMerchandisingList(res.data);
            return Promise.resolve();
        } catch (e) {
            message.error(e)
        }
        finally {
            setIsListLoading(false);
        }
    }, []);

    /**
     * @description Filter visual data based on name
     * @param dataIndex
     * @param searchValue
     */
    const onFiltersChange = (dataIndex, searchValue) => {
        const filteredVisualMerchandisingList = visualMerchandisingList.filter((visual) => {
            return visual[dataIndex]
                .toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase());
        });
        setFilteredVisualMerchandisingList(filteredVisualMerchandisingList);
    };

    useEffect(() => {
        getVisualMerchandisingList();
    }, [location, getVisualMerchandisingList]);

    return {
        filteredVisualMerchandisingList,
        onFiltersChange,
        isListLoading

    };
}

export const VisualMerchandisingProvider = ({ children }) => {
    const visualMerchandising = useProvideVisualMerchandising();

    return (
        <visualMerchandisingContext.Provider value={visualMerchandising}>
            {children}
        </visualMerchandisingContext.Provider>
    )
}

export const useVisualMerchandising = () => useContext(visualMerchandisingContext)
