import { useEffect } from 'react'
import { useLatestRef } from './useLatestRef'

export const useUnloadEffect = (cb: () => void) => {
    const cbRef = useLatestRef(cb)
    useEffect(() => {
        const callback = () => cbRef.current()
        window.addEventListener('beforeunload', callback)
        return () => {
            window.removeEventListener('beforeunload', callback)
        }
    }, [cbRef])
}
