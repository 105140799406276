import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useFilters } from "src/hooks/useFilters";
import { usePagination } from "src/hooks/usePagination";
import { useLocation } from "react-router-dom";
import { ProductsAPI } from "src/modules/products/api/ProductsAPI";
import { ProductsMapper } from "src/modules/products/domain/Products.mapper";
import { Product } from "src/services/Product.service";
import { message } from "src/services/Messages.service";

export const productsContext = createContext();

const useProvideProducts = () => {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [availableFilters, setAvailableFilters] = useState({ filters: [] });
  const [pagination, setPagination] = usePagination();
  const { query, onChangeQuery } = useFilters(ProductsMapper);
  const [pendingGetProducts, setPendingGetProducts] = useState(false)

  /**
   * @description Get list of products
   * @type {(function(): Promise<void|undefined>)|*}
   */
  const getProducts = useCallback(async () => {
    setPendingGetProducts(true)
    try {
      const res = await ProductsAPI.searchProducts(
        ProductsMapper.fromQueryToPayload(query)
      );
      const items = res.data.items.map((item) => ({
        ...item,
        published: Product.getStatus(item.published),
        macroProductCategory: {
          ...item.macroProductCategory,
          key: item.macroProductCategory.key,
          label: item.macroProductCategory.localeName,
        },
        microProductCategory: {
          ...item.microProductCategory,
          key: item.microProductCategory.key,
          label: item.microProductCategory.localeName,
        },
      }));
      setProducts(items);
      setAvailableFilters(res.data.availableFilters);
      setPagination(res.data);
      return Promise.resolve();
    } catch (e) {
      message.error(e);
    }
    finally {
      setPendingGetProducts(false)
    }
  }, [query, setPagination]);

  /**
   * @description Get product details
   * @type {(function(): Promise<void|undefined>)|*}
   */
  const getProduct = useCallback(async (id) => {
    try {
      return await ProductsAPI.getProduct(id);
    } catch (e) {
      // @todo handle notification
      return Promise.reject(e);
    }
  }, []);


  useEffect(() => {
    getProducts();
  }, [location.search, getProducts]);

  return {
    query,
    products,
    setProducts,
    availableFilters,
    pagination,
    onChangeQuery,
    refreshData: getProducts,
    pendingGetProducts,
    getProduct
  };
};

export const ProductsProvider = ({ children }) => {
  const products = useProvideProducts();
  return (
    <productsContext.Provider value={products}>
      {children}
    </productsContext.Provider>
  );
};

export const useProducts = () => useContext(productsContext);
