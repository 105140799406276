import React from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";

export const SearchInput = ({
  className = "",
  onChangeText,
  placeholder,
  defaultValue,
  id
}) => {
  return (
    <Input
      id={id}
      defaultValue={defaultValue}
      prefix={<SearchOutlined />}
      className={className}
      placeholder={placeholder}
      onChange={debounce((e) => onChangeText(e.target.value), 500)}
    />
  );
};
