import { AxiosClient } from "src/api/AxiosClient";
import { UpdateAvailabilityPayload } from "src/modules/warehouses/domain/models/UpdateAvailabilityPayload";

export class WarehousesAPI {
    // WAREHOUSES CRUD
    /**
     * @desc SearchWarehouses data
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async searchWarehouses(data) {
        return AxiosClient.get("v2/products/stocks", {params: data});
    }

    /**
     * @description updateWarehouse availability
     * @param record
     * @param availability
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async updateWarehouseAvailability(record, availability) {
        return AxiosClient.put(`/products/${record.productId}/variants/${record.variantId}/availabilities/${record.id}`, new UpdateAvailabilityPayload(record, availability));
    }


    /**
     * @desc Get warehouse list data to populate select
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getWarehousesList() {
        return AxiosClient.get(`/warehouses`)
    }
}
