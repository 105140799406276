import React, { useCallback } from "react";
import { Row, Col, Select, Button } from "antd";
import { useAttributes } from "src/hooks/useProvideAttributes";
import { InputSelectInfinite } from "src/components/inputs/InputSelectInfinite";

const { Option } = Select;

export const ProductAttribute = ({ value, onChange, onDelete, className }) => {
  const { attributeCodes, getAttributes } = useAttributes();

  const onLoadMore = useCallback(
    (page) => {
      return getAttributes(value.code, page);
    },
    [value.code, getAttributes]
  );

  const onChangeCode = useCallback(
    (code) => {
      onChange({
        ...value,
        code,
        value: null,
      });
    },
    [onChange, value]
  );

  const onChangeValue = useCallback(
    (v) => {
      onChange({
        ...value,
        value: v,
      });
    },
    [onChange, value]
  );

  return (
    <Row gutter={[16, 16]} className={className}>
      <Col span={8}>
        <Select
          value={value.code}
          onChange={onChangeCode}
          className="w-full"
          placeholder="Select"
        >
          {attributeCodes?.map((code) => (
            <Option key={code} value={code}>
              {code}
            </Option>
          ))}
        </Select>
      </Col>
      <Col span={8}>
        <InputSelectInfinite
          value={value}
          onLoadMore={onLoadMore}
          onChange={onChangeValue}
          placeholder="Select"
          disabled={!value.code}
        />
      </Col>
      <Col span={8} className="relative">
        <div className="absolute bottom-0">
          <Button
            className="uppercase delete-attribute-button"
            type="link"
            onClick={onDelete}
          >
            Remove attribute
          </Button>
        </div>
      </Col>
    </Row>
  );
};
