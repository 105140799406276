import React, { useState, createContext, useCallback, useEffect, useContext } from 'react'
import { useLocation } from "react-router-dom";
import { usePagination } from "src/hooks/usePagination";
import { useFilters } from "src/hooks/useFilters";
import { message } from "src/services/Messages.service";

import { WarehousesAPI } from "src/modules/warehouses/api/WarehousesApi";
import { WarehousesMapper } from "src/modules/warehouses/domain/Warehouses.mapper";

export const warehousesContext = createContext();
const useProvideWarehouses = () => {
    const location = useLocation()
    const [warehouses, setWarehouses] = useState([]);
    const [warehousesList, setWarehousesList] = useState([])
    const [filteredWarehousesList, setFilteredWarehousesList] = useState([]);
    const [isWarehouseListLoading, setWarehouseListLoading] = useState(false)
    const [pagination, setPagination] = usePagination();
    const { query, onChangeQuery } = useFilters(WarehousesMapper)

    /**
     * @description Get warehouseData
     * @type {(function(): Promise<void|undefined>)|*}
     */
    const getWarehouses = useCallback(async () => {
        setWarehouseListLoading(true)
        try {
            const res = await WarehousesAPI.searchWarehouses(
                WarehousesMapper.fromQueryToPayload(query)
            );
            setWarehouses(res.data?.items.map(warehouse => ({ ...warehouse, isUpdatePending: false })));
            setPagination(res.data);
        } catch (e) {
            message.error(e)
        } finally {
            setWarehouseListLoading(false)
        }
    }, [query, setPagination]);

    /**
     * @description get warehouse table data
     * @type {(function(): Promise<undefined>)|*}
     */
    const getWarehousesList = useCallback(async () => {
        try {
            const res = await WarehousesAPI.getWarehousesList();
            setWarehousesList(res.data?.data?.items);
            setFilteredWarehousesList(res.data?.data?.items);
        } catch (e) {
            message.error(e);
        }
    }, []);


    useEffect(() => {
        getWarehousesList()
    }, [getWarehousesList])

    useEffect(() => {
        getWarehouses();
    }, [location, getWarehouses]);

    /**
     * @description Update warehouse availability
     * @param record
     * @param availability
     * @returns {Promise<void>}
     */
    const updateWarehouseAvailability = useCallback(async (record, availability) => {
        try {
            const res = await WarehousesAPI.updateWarehouseAvailability(record, availability)
            return Promise.resolve(res);
        } catch (e) {
            return Promise.reject(e);
        }
    }, [])

    return {
        query,
        warehouses,
        setWarehouses,
        pagination,
        onChangeQuery,
        warehousesList,
        isWarehouseListLoading,
        getWarehousesList,
        updateWarehouseAvailability,
        filteredWarehousesList,
        setFilteredWarehousesList
    };
}

export const WarehousesProvider = ({ children }) => {
    const warehouses = useProvideWarehouses();

    return (
        <warehousesContext.Provider value={warehouses}>
            {children}
        </warehousesContext.Provider>
    )
}

export const useWarehouses = () => useContext(warehousesContext)
