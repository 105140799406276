import React from "react";
import { Input, Form, Select } from "antd";
import { InputSelectSearch } from "src/components/inputs/InputSelectSearch";
import { InputSelectInfinite } from "src/components/inputs/InputSelectInfinite";
import { InputSelectInfiniteWithSearch } from "src/components/inputs/InputSelectInfiniteWithSearch";

const { Option } = Select;
export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  inputOptions,
  record,
  children,
  updateInputValue,
  onLoadMore,
  onChange,
  placeholder = "Select",
  onMount,
  ...restProps
}) => {
  const getEditableChildren = () => {
    switch (inputType) {
      case "select": {
        return (
          <InputSelectSearch placeholder={placeholder} items={inputOptions} />
        );
      }

      case "text": {
        return <Input />;
      }

      // TODO This is a special case to handle that's why it is treated in this way
      case "published-select": {
        return (
          <Select placeholder={placeholder}>
            {inputOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        );
      }

      case "infinite-select": {
        return (
          <InputSelectInfinite
            onLoadMore={onLoadMore}
            onChange={onChange}
            placeholder={placeholder}
            onMount={onMount}
          />
        );
      }

      case "infinite-select-search": {
        return (
          <InputSelectInfiniteWithSearch
            onLoadMore={onLoadMore}
            onChange={onChange}
            placeholder={placeholder}
            onMount={onMount}
          />
        );
      }

      default: {
        return <h1>Check Input Type</h1>;
      }
    }
  };

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {getEditableChildren()}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
