import React from "react";
import { Layout } from "antd";
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "src/router/Routes.helper";
import { SideBar } from "src/components/navigation/SideBar";
import { PageDashboard } from "src/modules/dashboard/pages/PageDashboard";
import { PageProducts } from "src/modules/products/pages/PageProducts";
import { PageProductDetails } from "src/modules/products/pages/PageProductDetails";
import { ProductsProvider } from "src/modules/products/provider/products.provider";
import { ProductDetailsProvider } from "src/modules/products/provider/productDetails.provider";
import { PageWarehouses } from "src/modules/warehouses/pages/PageWarehouses";
import { WarehousesProvider } from "src/modules/warehouses/provider/warehouses.provider";
import { AttributesProvider } from "src/hooks/useProvideAttributes";
import { PageUploadsList } from "src/modules/upload/pages/PageUploadsList";
import { UploadsListProvider } from "src/modules/upload/provider/uploadsList.provider";
import { PageUploadTypeDetails } from "src/modules/upload/pages/PageUploadTypeDetails";
import { UploadTypeDetailsProvider } from "src/modules/upload/provider/uploadTypeDetails.provider";
import { UploadTypeStatusProvider } from "src/modules/upload/provider/uploadTypeStatus.provider";
import { PageUploadTypeStatus } from "src/modules/upload/pages/PageUploadTypeStatus";
import { VisualMerchandisingProvider } from "src/modules/visualMerchandising/provider/visualMerchandisingList.provider";
import { PageVisualMerchandisingList } from "src/modules/visualMerchandising/pages/PageVisualMerchandisingList";
import { VisualMerchandisingProductsProvider } from "src/modules/visualMerchandising/provider/visualMerchandisingProducts.provider";
import { PageVisualMerchandisingProducts } from "src/modules/visualMerchandising/pages/PageVisualMerchandisingProducts";
import { DashboardProvider } from "src/modules/dashboard/provider/dashboard.provider";
const { Content } = Layout;

export const AppLayout = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <SideBar />
      <Layout className="site-layout">
        <Content style={{ margin: "0 16px" }}>
          <Switch>
            <Route exact path={Routes.PATHS.DASHBOARD}>
              <DashboardProvider>
                <PageDashboard />
              </DashboardProvider>
            </Route>

            <AttributesProvider>
              <Switch>
                <Route exact path={Routes.PATHS.PRODUCTS}>
                  <ProductsProvider>
                    <PageProducts />
                  </ProductsProvider>
                </Route>

                <Route exact path={Routes.PATHS.PRODUCTS_DETAILS}>
                  <ProductDetailsProvider>
                    <PageProductDetails />
                  </ProductDetailsProvider>
                </Route>

                <Route exact path={Routes.PATHS.WAREHOUSE}>
                  <WarehousesProvider>
                    <PageWarehouses />
                  </WarehousesProvider>
                </Route>

                <Route exact path={Routes.PATHS.UPLOADS_LIST}>
                  <UploadsListProvider>
                    <PageUploadsList />
                  </UploadsListProvider>
                </Route>

                <Route exact path={Routes.PATHS.UPLOAD_TYPE_DETAILS}>
                  <UploadTypeDetailsProvider>
                    <PageUploadTypeDetails />
                  </UploadTypeDetailsProvider>
                </Route>

                <Route exact path={Routes.PATHS.PAGE_UPLOAD_TYPE_STATUS}>
                  <UploadTypeStatusProvider>
                    <PageUploadTypeStatus />
                  </UploadTypeStatusProvider>
                </Route>

                <Route exact path={Routes.PATHS.VISUAL_MERCHANDISING}>
                  <VisualMerchandisingProvider>
                    <PageVisualMerchandisingList />
                  </VisualMerchandisingProvider>
                </Route>

                <Route exact path={Routes.PATHS.VISUAL_MERCHANDISING_PRODUCTS}>
                  <VisualMerchandisingProductsProvider>
                    <PageVisualMerchandisingProducts />
                  </VisualMerchandisingProductsProvider>
                </Route>

                <Redirect to={Routes.PATHS.DASHBOARD} />
              </Switch>
            </AttributesProvider>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};
