export const TIPS_AND_SUGGESTIONS = [
    {
        key: "no_spaces",
        value: "Don't write with spaces"
    },
    {
        key: "uppercase_lowercase",
        value: "Pay attention to lowercase and uppercase letters"
    },
    {
        key: "another_tip_1",
        value: "text"
    },
    {
        key: "another_tip_2",
        value: "text"
    },
]
