import React, { useCallback, useEffect } from "react";
import { Button, Form, Input, Row } from "antd";
import { message } from "src/services/Messages.service";
import { ProductsAPI } from "src/modules/products/api/ProductsAPI";
import { SectionTitle } from "src/components/forms/SectionTitle";
import { ImageGallery } from "src/modules/products/components/ImageGallery";
import { HelperText } from "src/components/forms/HelperText";
import { InputSelectInfinite } from "src/components/inputs/InputSelectInfinite";
import { useAttributes } from "src/hooks/useProvideAttributes";

export const VariantDetails = ({
    variant,
    productId,
    onEditVariant,
    closeDrawer,
    selectedVariantAvailabilities,
    onGalleryOrderUpdate,
    onGalleryUpdate,
    variantGalleryFiles
}) => {
    const [form] = Form.useForm();
    const { getAttrsForInfiniteSelect } = useAttributes();
    useEffect(() => {
        if (!variant) return;
        let fieldsValue = {
            color: {
                key: variant.attributeVariants.color.key,
                label: variant.attributeVariants.color.localeName,
                id: variant.attributeVariants.color.id,
            },
            sizeScale: {
                key: variant.attributeVariants.sizeScale,
                label: variant.attributeVariants.sizeScale,
            },
            size: {
                key: variant.attributeVariants.size,
                label: variant.attributeVariants.size,
            },
            manufacturerCode: variant.manufacturerCode,
            price: variant.prices ? variant.prices.sellingPrice : null,
            availabilities: selectedVariantAvailabilities.reduce((acc, variant) => {
                acc[variant.id] = variant.availability
                return acc
            }, {})
        };
        form.setFieldsValue(fieldsValue);
    }, [form, variant, selectedVariantAvailabilities]);

    const onChangeValue = useCallback((code, value) => {
        // form.setFieldsValue({
        //   [code]: {
        //     key: value.key,
        //     label: value.children,
        //     code,
        //   },
        // });
    }, []);

    const deleteVariant = async () => {
        try {
            await ProductsAPI.deleteVariant(productId, variant.id);
            message.success("Variant deleted successfully");
            closeDrawer();
        } catch (e) {
            message.error(e);
        }
    };

    return (
        <div>
            <Form
                onFinish={onEditVariant}
                form={form}
                layout="vertical"
                autoComplete="off"
                initialValues={{
                    color: { key: null, label: null, id: null },
                    sizeScale: { key: null, label: null },
                    size: { key: null, label: null },
                    manufacturerCode: null,
                    price: null,
                    availability: [],
                }}
                scrollToFirstError
                onValuesChange={console.log}
            >
                <div className="relative pb-16">
                    {/*Manufacture Code*/}
                    <Row className="px-4 py-4">
                        <Form.Item
                            className="w-full"
                            name="manufacturerCode"
                            label="EAN"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your manufacturer Code!",
                                },
                            ]}
                        >
                            <Input placeholder="EAN" />
                        </Form.Item>
                    </Row>

                    {/*Color*/}
                    <Row className="px-4 pb-4">
                        <Form.Item
                            className="w-full"
                            name="color"
                            label="Color"
                            rules={[{ required: true, message: "Please select variant color" }]}
                        >
                            <InputSelectInfinite
                                onLoadMore={(page) => getAttrsForInfiniteSelect("color", page)}
                                onChange={onChangeValue}
                            />
                        </Form.Item>
                    </Row>

                    {/*Scale*/}
                    <Row className="px-4 pb-4">
                        <Form.Item
                            className="w-full"
                            name={"sizeScale"}
                            label="Scale"
                            rules={[
                                { required: true, message: "Please input your variant scale" },
                            ]}
                        >
                            <InputSelectInfinite
                                onLoadMore={(page) =>
                                    getAttrsForInfiniteSelect("sizeScale", page)
                                }
                                onChange={onChangeValue}
                            />
                        </Form.Item>
                    </Row>

                    {/*Size*/}
                    <Row className="px-4 pb-4">
                        <Form.Item
                            name={"size"}
                            label="Size"
                            className="w-full"
                            rules={[
                                { required: true, message: "Please input your variant size" },
                            ]}
                        >
                            <InputSelectInfinite
                                onLoadMore={(page) => getAttrsForInfiniteSelect("size", page)}
                                onChange={onChangeValue}
                            />
                        </Form.Item>
                    </Row>

                    <hr className="mt-2" />

                    <Row className="px-4 pt-4">
                        <SectionTitle title="Prices"></SectionTitle>
                    </Row>

                    {/*Prices*/}
                    <Row className="px-4 pb-4">
                        <Form.Item
                            name="price"
                            label="Basic price"
                            className="w-full px-4 pb-4"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject(new Error('Please input a price'));
                                        } else if (value && getFieldValue('price') < 0) {
                                            return Promise.reject(new Error('Price must be greater than zero'));
                                        } else return Promise.resolve();
                                    },
                                })]}
                        >
                            <Input
                                min="0"
                                step="0.1"
                                addonBefore={variant.prices?.currency?.symbol}
                                placeholder="Insert Price"
                                className="w-full"
                            />
                        </Form.Item>
                    </Row>
                    <hr className="mt-2" />
                    {/*Gallery*/}
                    <Row className="px-4 pt-4">
                        <SectionTitle title="Gallery"></SectionTitle>
                        <HelperText text="You can use drag & drop to reorder images"></HelperText>
                    </Row>
                    <Row className="pl-4 pb-4">
                        <ImageGallery
                            fileList={variantGalleryFiles}
                            onGalleryUpdate={(file, fileList) => onGalleryUpdate(file, fileList)}
                            onGalleryOrderUpdate={(fileList) => onGalleryOrderUpdate(fileList)}
                        />
                    </Row>
                    {!!selectedVariantAvailabilities.length && <hr className="mt-2" />}
                    <Row className="pl-4 pb-4">
                        <SectionTitle title="Availability"></SectionTitle>
                        {selectedVariantAvailabilities.length
                            ? selectedVariantAvailabilities.map((availability, index) => (
                                <Form.Item
                                    key={"random-prefix" + availability.id}
                                    name={["availabilities", availability.id]}
                                    label={availability.warehouse?.name}
                                    className="w-full px-4 pb-4"
                                    rules={[
                                        { required: true, message: "Please input availability" },
                                    ]}
                                >
                                    <Input
                                        type="number"
                                        min="0"
                                        step="0.1"
                                        placeholder="Insert availability"
                                        className="w-full"
                                    />
                                </Form.Item>
                            ))
                            : "There are no availability"}
                    </Row>
                    <Row className="p-4">
                        {/*Delete Product*/}
                        <Button
                            type="danger"
                            htmlType="button"
                            className="uppercase"
                            onClick={deleteVariant}
                        >
                            Delete Variant
                        </Button>
                    </Row>
                </div>
                <div className="absolute bottom-0 z-40 bg-white flex w-full justify-end pr-6 py-4">
                    <Button type="text" className="mr-2 uppercase" onClick={closeDrawer}>
                        Cancel
                    </Button>
                    <Button className="uppercase" type="primary" htmlType="submit">
                        Save Changes
                    </Button>
                </div>
            </Form>
        </div>
    );
};
