import React, { useState, useCallback } from "react";
import { Upload, Modal, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DragableUploadListItem } from "src/modules/products/components/DragableUploadListItem";
import update from "immutability-helper";
import { Fragment } from "react";

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);

export const ImageGallery = ({ fileList, onGalleryUpdate, onGalleryOrderUpdate }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = fileList[dragIndex];
        onGalleryOrderUpdate(update(fileList, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRow],
            ],
        }))
    },
    [fileList, onGalleryOrderUpdate]
  );

    return (
    <DndProvider backend={HTML5Backend}>
      <Upload
        accept="image/*"
        listType="picture-card"
        onChange={({file, fileList}) => onGalleryUpdate(file, fileList)}
        fileList={fileList}
        beforeUpload={() => {
            return false;
        }}
        onPreview={handlePreview}
        itemRender={(originNode, file, currFileList) => (
          <Fragment key={file.uid}>
            <DragableUploadListItem
              originNode={originNode}
              file={file}
              fileList={currFileList}
              moveRow={moveRow}
            />
            {currFileList[0]?.uid === file?.uid && (
              <Tag color="geekblue">Image Cover</Tag>
            )}
          </Fragment>
        )}
      >
        {fileList?.length < 100 && uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </DndProvider>
  );
};
