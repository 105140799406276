import axios from "axios";
import { StorageService } from "src/services/Storage.service";
import { history } from "src/App";
import { Routes } from "src/router/Routes.helper";

export const AxiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
  headers: {
    "X-Domain": "demovec",
    "accept-language": "it",
  },
});

AxiosClient.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error?.response?.status === 401) {
      StorageService.deleteUser();
      history.push(Routes.PATHS.LOGIN);
    }
    return Promise.reject(error);
  }
);
