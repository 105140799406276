import React, { useState, useEffect } from "react";
import { Row, Col, Button, Select, Form } from "antd";
import { message } from "src/services/Messages.service";
import { useParams } from "react-router-dom";
import { useUploadTypeDetails } from "src/modules/upload/provider/uploadTypeDetails.provider";
import { useDownloadFile } from "src/hooks/useDownloadFile";
import { BackButton } from "src/components/BackButton";
import { PageTitle } from "src/components/PageTitle";
import { HelperText } from "src/components/forms/HelperText";
import { FileUpload } from "src/modules/upload/components/FileUpload";
import { SAMPLE_TEMPLATE_LINKS } from "src/constants/sampleTemplateLinks";
import { UploadTypes } from "src/modules/upload/domain/models/UploadTypes";

const { Option } = Select;
const { TYPE_KEYS } = UploadTypes;

// These are being used in case a warehouse input is not needed - check if category or attribute set warehouseId = type otherwise to selected warehouseId
const pageParams = [TYPE_KEYS.CATEGORY, TYPE_KEYS.ATTRIBUTE];

export const PageUploadTypeDetails = () => {
  const params = useParams();
  const [form] = Form.useForm();
  const { uploadTypeDetails, uploadFile, warehousesList } = useUploadTypeDetails();
  const [warehouseId, setWarehouseId] = useState();
  const [uploadedFile, setUploadedFile] = useState();
  const [hasDownloadCatalog, setHasDownloadCatalog] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [sampleTemplateLink, setSampleTemplateLink] = useState()
  const downloadFile = useDownloadFile({onError(err) {message.error(err.response?.data?.detail || err.response?.statusText || err.message);}});
  const downloadCatalogFile = useDownloadFile({onError(err) {message.error(err.response?.data?.detail || err.response?.statusText || err.message);}});

  // In case of type category or attribute set the warehouseId = params.type
  useEffect(() => {
    if (pageParams.includes(params.type)) {
      setWarehouseId(params.type);
    }
  }, [params.type]);

  // Display download catalog only in case type is product
  useEffect(() => {
    setHasDownloadCatalog(params.type === TYPE_KEYS.PRODUCT);
    setSampleTemplateLink(SAMPLE_TEMPLATE_LINKS[params.type])
  }, [params]);

  /**
   * @description Upload File
   * @returns {Promise<void>}
   */
  const handleUpload = async () => {
    await form.validateFields();
    setUploadLoading(true);
    try {
      await uploadFile(warehouseId, uploadedFile);
      setUploadLoading(false);
      message.success("File submitted successfully");
    } catch (e) {
      setUploadLoading(false);
      message.error(e);
    }
  };

  return (
    <>
      <Row className="py-4">
        <Col span={12}>
          <BackButton
            title="Back to uploads"
            className="pb-4 text-sm"
          ></BackButton>
          <PageTitle>{uploadTypeDetails.title}</PageTitle>
          <p className="pt-4">{uploadTypeDetails.description}</p>
          <HelperText
            className="pt-4"
            text={`Required format: .${uploadTypeDetails.formats}`}
          ></HelperText>
          <HelperText
            className="pb-4"
            text={`Maximum weight  ${uploadTypeDetails.max_wight} mb`}
          ></HelperText>
          <Row gutter={[16, 16]} className="pt-4">
            <Col>
              <Button
                type="primary"
                className="uppercase"
                loading={downloadFile.isLoading}
                onClick={() =>
                    downloadFile.call(sampleTemplateLink, `${params.type}_sample_template.xlsx`)
                }
              >
                Download Template .XLS
              </Button>
            </Col>
            {/*Display Download catalog button only in case of product type*/}
            {hasDownloadCatalog && (
              <Col>
                <Button
                  loading={downloadCatalogFile.isLoading}
                  onClick={() => downloadCatalogFile.call("/backoffice/export", "catalog.xlsx")}
                  type="link"
                  className="uppercase">
                  Download Catalog.xls
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={6}>
          <Form
            name="basic"
            form={form}
            initialValues={{
              warehouse: null,
              file: null,
            }}
            onFinish={handleUpload}
            autoComplete="off"
            scrollToFirstError
          >
            {/*Warehouse input select which is going to be visible only in case of product and  stock type*/}
            {!pageParams.includes(params.type) && (
              <Form.Item
                className="warehouse-select"
                name="warehouse"
                label="Warehouses"
                rules={[{ required: true, message: `Warehouse is required` }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Warehouse"
                  onChange={(value) => setWarehouseId(value)}
                  allowClear
                >
                  {warehousesList.map((warehouse) => (
                    <Option
                      key={warehouse.name}
                      value={warehouse.warehouseIdentifier}
                    >
                      {warehouse.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {/*Drag and drop section*/}
            <Form.Item name="file" rules={[{ required: true, message: `` }]}>
              <FileUpload
                accept={uploadTypeDetails.formats}
                fileSize={uploadTypeDetails.max_wight}
                acceptedFileTypes={uploadTypeDetails.formats}
                uploadedFile={uploadedFile}
                onUploadedFileChange={(file) => {
                  setUploadedFile(file);
                  form.setFieldsValue({ file: file });
                }}
              ></FileUpload>
            </Form.Item>

            {/*Upload*/}
            <div className="flex justify-end">
              <Button
                loading={uploadLoading}
                className="uppercase"
                type="primary"
                htmlType="submit"
                style={{ marginTop: 16 }}
              >
                Upload
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};
