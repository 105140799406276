import { useMemo } from "react";
import { Product } from "src/services/Product.service";
import omit from "lodash/omit";

export const useFilterTags = (query, searchParameters, availableFilters) => {
  const parsedQuery = useMemo(() => {
    return omit(query, searchParameters);
  }, [query, searchParameters]);

  return useMemo(
    () =>
      Object.entries(parsedQuery).reduce((acc, [filterKey, filterVal]) => {
          // //TODO - Changes this workaround later
          if (filterVal === "true" || filterVal === "false") {
              filterVal = Product.stringToBoolean(filterVal)
          }
        switch (typeof filterVal) {
          case "boolean": {
            acc.push({
              key: filterKey,
              value: filterVal,
              label: filterVal,
            });
            return acc;
          }
          case "string": {
            acc.push({
              key: filterKey,
              value: filterVal,
              label: filterVal,
            });
            return acc;
          }
          case "object": {
            if (!Array.isArray(filterVal)) return acc;
            acc.push(
              ...filterVal.map((val) => {
                let label = val;
                if (filterKey === "macroCategory") {
                  label = availableFilters?.["macro_category.key"]?.find(
                    (e) => e.key === val
                  )?.value;
                }
                if (filterKey === "microCategory") {
                  label = availableFilters?.["micro_category.key"]?.find(
                    (e) => e.key === val
                  )?.value;
                }

                return {
                  key: filterKey,
                  value: val,
                  label,
                };
              })
            );
            return acc;
          }
          default:
            return acc;
        }
      }, []),
    [parsedQuery, availableFilters]
  );
};
