import React from 'react'
import { Col, Row, Table } from "antd";
import { Routes } from "src/router/Routes.helper";
import { Link } from "react-router-dom";
import { PageTitle } from "src/components/PageTitle";
import { SearchInput } from "src/components/inputs/SearchInput";
import { useVisualMerchandising } from "src/modules/visualMerchandising/provider/visualMerchandisingList.provider";
import Moment from "react-moment";
import moment from "moment";
import {useTableHeight} from "src/hooks/useTableHeight";

export const PageVisualMerchandisingList = () => {
    const { onFiltersChange, filteredVisualMerchandisingList, isListLoading } = useVisualMerchandising();
    const handleVisualMerchandisingSearch = (value) => {
        onFiltersChange('name', value)
    }
    const tableHeight = useTableHeight();

    const columns = [
        {
            title: "Name",
            dataIndex: ["name"],
            render: (_, { name, advancedFilters, basicFilters, filters, id }) => (
                <Link
                    to={Routes.parseRouteParams(Routes.PATHS.VISUAL_MERCHANDISING_PRODUCTS, {
                        "visualMerchandising": name,
                        "VisualMerchandisingId": id
                    }) + Routes.toUrlSearch({ advancedFilters, basicFilters, filters })}
                >
                    {name}
                </Link>
            ),
        },
        {
            title: "Area",
            dataIndex: ["filters"],
            width: "25%",
            render: (filters) => filters.map((filter, i) => [
                i > 0 && ", ",
                filter.key

            ])
        },
        {
            title: "Articles",
            width: "15%",
            dataIndex: ["numberOfItems"],
            align: 'right'
        },
        {
            title: "Created on",
            dataIndex: ["creationDate"],
            sorter: (a, b) => moment(a.creationDate).unix() - moment(b.creationDate).unix(),
            render: (text) => <Moment format="DD MMM YYYY" date={text} />
        },
        {
            title: "Last Edit",
            dataIndex: ["updateDate"],
            key: 'updateDate',
            defaultSortOrder: 'descend',
            sorter: (a, b) => moment(a.updateDate).unix() - moment(b.updateDate).unix(),
            render: (text) => <Moment format="DD MMM YYYY" date={text} />,
        },
    ]
    return (
        <div>
            <div className="py-4 page-filters">
                <div className="py-4">
                    <PageTitle>Visual Merchandising</PageTitle>
                </div>
                <Row gutter={[16, 16]} className="pb-4">
                    <Col span={6}>
                        <SearchInput placeholder="Search a visual merchandising" onChangeText={handleVisualMerchandisingSearch} />
                    </Col>
                </Row>
            </div>

            <Row>
                <Table
                    size="middle"
                    dataSource={filteredVisualMerchandisingList}
                    rowKey="id"
                    columns={columns}
                    scroll={{ y: tableHeight }}
                    pagination={false}
                    loading={isListLoading}
                />
            </Row>
        </div>
    );
};
