import React, { useState, useEffect } from "react";
import { Row, Col, Table, List, Button, Tooltip, Card, Skeleton } from "antd";
import { message } from "src/services/Messages.service"
import { useUploadTypeStatus } from "src/modules/upload/provider/uploadTypeStatus.provider"
import { useDownloadFile } from "src/hooks/useDownloadFile";
import { useParams } from "react-router-dom";
import { BackButton } from "src/components/BackButton";
import { PageTitle } from "src/components/PageTitle";
import { SectionTitle } from "src/components/forms/SectionTitle";
import { UPLOAD_STATUS } from "src/constants/uploadStatus";
import { TIPS_AND_SUGGESTIONS } from "src/constants/tipsAndSuggestions";
import { SAMPLE_TEMPLATE_LINKS } from "src/constants/sampleTemplateLinks";
import { UPLOAD_FILE_TYPE_TITLE } from "src/constants/uploadFileTypeTitle";
import Moment from "react-moment";
import fileDownload from "js-file-download";
import isEmpty from "lodash/isEmpty";

export const PageUploadTypeStatus = () => {
    const params = useParams();
    const [sampleTemplateLink, setSampleTemplateLink] = useState();
    const { UUIDStatus, UUIDErrorFile, pendingGetStatus } = useUploadTypeStatus();

    // Display download catalog only in case type is product
    useEffect(() => {
        setSampleTemplateLink(SAMPLE_TEMPLATE_LINKS[params.type])
    }, [params])


    const downloadFile = useDownloadFile({
        onError(err) {
            message.error(err.response?.data?.detail || err.response?.statusText || err.message);
        },
    });

    /**
     * @description Folder history table's columns
     * @type {[{dataIndex: string, title: string, key: string}, {dataIndex: string, title: string, key: string}, {dataIndex: string[], title: string, render: (function(*))}, {dataIndex: string[], title: string, render: (function(*, *)), key: string}]}
     */
    const columns = [
        {
            title: 'File name',
            dataIndex: 'errorFilePath',
            render: (text) => <Tooltip title={text}><p className="line-clamp-1">{text}</p></Tooltip>
        },
        {
            title: 'Type',
            dataIndex: 'type',
            render: (type) => UPLOAD_FILE_TYPE_TITLE[type]
        },
        {
            title: "Uploaded on",
            dataIndex: ["creationDate"],
            render: (text) => <Moment format="HH:mm DD MMM YYYY" date={text} />,
        },
        {
            title: "Status",
            dataIndex: ["status"],
            render: (_, record) => (
                `${UPLOAD_STATUS[record.status]} ${record.errors && record.errors.importErrors[0] ? record.errors.importErrors[0].errors.length : ''} errors`
            ),
        },
    ]

    return (
        <>
            {/*Back button*/}
            <Row className="py-4">
                <Col span={12}>
                    <BackButton
                        title="Back to uploads"
                        className="pb-4 text-sm"
                    ></BackButton>
                </Col>
            </Row>
            <PageTitle>{UUIDStatus.errorFilePath}</PageTitle>
            {/*Uploaded file table data*/}
            <Row className="py-8">
                <Col xs={24} xl={18}>
                    <Table
                        size="middle"
                        dataSource={[UUIDStatus]}
                        rowKey="uuid"
                        columns={columns}
                        pagination={false}
                    />
                </Col>
            </Row>
            <Row>
                {/*TODO Update info text later - temporary*/}
                <PageTitle>Ups! It seems something went wrong.</PageTitle>
            </Row>
            <Row gutter={[16, 16]} className="pt-8">
                {/*What it is not matching section*/}
                <Col span={12}>
                    <Card>
                        <SectionTitle title="Here what is not matching:" className="pb-4" />
                        <div className="h-64 overflow-y-auto">
                            {(UUIDStatus.errors?.importErrors[0]) ?
                                <List className="errorList"
                                    rowKey="id"
                                    dataSource={UUIDStatus.errors.importErrors[0].errors}
                                    renderItem={(item, index) => (
                                        <List.Item>
                                            {index + 1}. {item.details}
                                        </List.Item>
                                    )}
                                /> : <p className="italic">No errors </p>}
                            {pendingGetStatus && <Skeleton active className="h-full"></Skeleton>}
                        </div>
                    </Card>
                </Col>
                {/*Suggestion section*/}
                <Col span={12}>
                    <Card>
                        <SectionTitle title="Here some tips and suggestions:" className="pb-4" />
                        <div className="h-64 overflow-y-auto">
                            <List
                                rowKey="key"
                                dataSource={TIPS_AND_SUGGESTIONS}
                                renderItem={(item) => (
                                    <List.Item>
                                        {item.value}
                                    </List.Item>
                                )}
                            />
                        </div>
                    </Card>

                </Col>
            </Row>
            <Row gutter={[16, 16]} className="pt-8">
                <Col span={12} className="pb-8">
                    <Button
                        type="primary"
                        className="uppercase"
                        onClick={() => (!UUIDErrorFile || isEmpty(UUIDErrorFile)) ? message.info('Error file not available') : fileDownload(UUIDErrorFile, UUIDStatus.errorFilePath)
                        }
                    >
                        Download File With Errors
                    </Button>
                </Col>
                <Col span={12} className="pb-8">
                    <Button
                        type="link"
                        className="uppercase"
                        loading={downloadFile.isLoading}
                        onClick={() => {
                            downloadFile.call(sampleTemplateLink, `${params.type}_sample_template.xlsx`)
                        }
                        }
                    >
                        Download Template .XLS
                    </Button>
                </Col>
            </Row>
        </>
    );
};
