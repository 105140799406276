import React, { useMemo, useState } from "react";
import { Row, Col, Drawer } from "antd";
import { ProductsAPI } from "../api/ProductsAPI";
import { message } from "src/services/Messages.service";
import { useProductDetails } from "src/modules/products/provider/productDetails.provider";
import { EditableTable } from "src/modules/products/components/EditableTable";
import { SearchInput } from "src/components/inputs/SearchInput";
import { VariantDetails } from "src/modules/products/components/VariantDetails";
import Moment from "react-moment";
import moment from "moment";
import { useAttributes } from "src/hooks/useProvideAttributes";
import { useImmer } from "use-immer";
import { ProductStatusTag } from "src/modules/products/components/ProductStatusTag";
import { Product } from "src/services/Product.service";

export const ProductVariants = ({ variants, productId, onEditVariant, onQuickEditVariant }) => {
    const { onFiltersChange } = useProductDetails();
    const [selectedVariant, setSelectedVariant] = useImmer(null);
    const [selectedVariantAvailabilities, setSelectedVariantAvailabilities] =
        useState([]);
    const { getAttrsForInfiniteSelect } = useAttributes();
    const [drawerVisible, setDrawerVisible] = useState(false);
    const { getVariantAvailabilities } = useProductDetails();

    const [variantGalleryFiles, setVariantGalleryFiles] = useState([])
    const [addedVariantGalleryFiles, setAddedVariantGalleryFiles] = useState([])
    const [deletedVariantGalleryFiles, setDeletedVariantGalleryFiles] = useState([])

    const columns = useMemo(
        () => [
            {
                title: "EAN",
                dataIndex: ["manufacturerCode"],
                render: text => <a href="plainText">{text}</a>,
            },
            {
                title: "Color",
                dataIndex: ["attributeVariants", "color"],
                render: (_, record) => (
                    <div>{record.attributeVariants.color.label}</div>
                ),
                editable: true,
                inputType: "infinite-select",
                onLoadMore: (page) => getAttrsForInfiniteSelect("color", page),
            },
            {
                title: "Scale",
                dataIndex: ["attributeVariants", "sizeScale"],
                editable: true,
                inputType: "infinite-select",
                onLoadMore: (page) => getAttrsForInfiniteSelect("sizeScale", page),
            },
            {
                title: "Size",
                dataIndex: ["attributeVariants", "size"],
                editable: true,
                inputType: "infinite-select",
                onLoadMore: (page) => getAttrsForInfiniteSelect("size", page),
            },
            {
                title: "Price",
                dataIndex: ["prices", "sellingPrice"],
                render: (_, record) => (
                    <div>
                        {record.prices?.currency?.symbol} {record.prices?.sellingPrice}
                    </div>
                ),
                editable: true,
                inputType: "text",

            },
            //TODO Generalize ProductStatusTag and product service because it is being used on product and variant table - to save time now use like this
            {
                title: "Status",
                dataIndex: ["published"],
                render: (status) => <ProductStatusTag status={status} />,
                inputType: "published-select",
                editable: true,
                inputOptions: Product.statusOptions,
            },
            {
                title: "Last Edit",
                dataIndex: ["updateDate"],
                sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
                render: (text) => <Moment format="DD MMM YYYY" date={text} />,
            },
        ],
        [getAttrsForInfiniteSelect]
    );

    const handleDelete = async (variant) => {
        try {
            await ProductsAPI.deleteVariant(productId, variant.id);
            message.success("Variant deleted successfully");
        } catch (e) {
            message.error(e);
        }
    };
    const handleVariantSearch = (value) => {
        const lookupList = ['manufacturerCode', 'attributeVariants.color.label', 'attributeVariants.sizeScale', 'attributeVariants.size', 'prices.sellingPrice', 'updateDate']
        onFiltersChange(lookupList, value);
    };

    const handleRowClick = async (variant) => {
        setSelectedVariant(() => variant);
        setVariantGalleryFiles(variant?.photoGallery?.allImages || [])
        setDrawerVisible(true);
        try {
            const res = await getVariantAvailabilities(productId, variant.id);
            setSelectedVariantAvailabilities(res.data?.data?.items);
        } catch (e) {
            message.error(e);
        }
    };

    // const handleImageAdd = async (file) => {
    //     try {
    //         const res = await ProductsAPI.addVariantGalleryImage(
    //             productId,
    //             selectedVariant.id,
    //             file
    //         );
    //         message.success("Image added successfully");
    //         setSelectedVariant((variant) => {
    //             //add image here
    //             variant.photoGallery.images.push(file);
    //         });
    //         return res;
    //     } catch (e) {
    //         message.error(e);
    //     }
    // };
    //
    // const handleImageDelete = async (file) => {
    //     try {
    //         const res = await ProductsAPI.deleteVariantGalleryImage(
    //             productId,
    //             selectedVariant.id,
    //             file.uid
    //         );
    //         message.success("Image removed successfully");
    //         setSelectedVariant((variant) => {
    //             variant.photoGallery.images = variant.photoGallery.images.filter(
    //                 (image) => image.id !== file?.uid
    //             );
    //         });
    //         return res;
    //     } catch (e) {
    //         message.error(e);
    //     }
    // };

    /**
     * @description Handle delete image from gallery method
     * @param file
     */
    const handleDeleteVariantGalleryFile = (file) => {
        if (addedVariantGalleryFiles.find(addedFile => addedFile.uid === file.uid)) {
            setAddedVariantGalleryFiles((oldAddedFileList) => oldAddedFileList.filter(addedFile => addedFile.uid !== file.uid))
        }
        if (file.uid.includes('rc-upload')) {
            return;
        }
        setDeletedVariantGalleryFiles((oldDeletedFileList) => [...oldDeletedFileList, file])
    }

    /**
     * @description Handle add image to gallery method
     * @param file
     */
    const handleAddedVariantGalleryFile = (file) => {
        setAddedVariantGalleryFiles((oldAddedVariantFileList) => [...oldAddedVariantFileList, file])
    }

    /**
     * @description Handle Gallery update
     * @param file
     * @param fileList
     */
    const handleGalleryUpdate = (file, fileList) => {
        setVariantGalleryFiles(fileList)
        if (file?.status === "removed") {
            handleDeleteVariantGalleryFile(file)
        } else {
            handleAddedVariantGalleryFile(file)
        }
    }

    const handleGalleryOrderUpdate = (fileList) => {
        console.log("Ordered file list", fileList)
    }

    return (
        <div>
            <Row gutter={[16, 16]} className="pb-4">
                <Col span={6}>
                    <SearchInput
                        placeholder="Start typing an EAN code or color..."
                        onChangeText={handleVariantSearch}
                    />
                </Col>
            </Row>
            <EditableTable
                onDelete={handleDelete}
                tableData={variants}
                columns={columns}
                pagination={false}
                deleteMessage={"You have just deleted {manufacturerCode} variant"}
                onRowClick={handleRowClick}
                onUpdateRecord={(formData, selectedVariant) =>
                    onQuickEditVariant(selectedVariant, {
                        color: formData.attributeVariants.color,
                        size: {
                            key:
                                formData.attributeVariants.size?.key ??
                                formData.attributeVariants.size,
                            label:
                                formData.attributeVariants.size?.key ??
                                formData.attributeVariants.size,
                        },
                        sizeScale: {
                            key:
                                formData.attributeVariants.sizeScale?.key ??
                                formData.attributeVariants.sizeScale,
                            label:
                                formData.attributeVariants.sizeScale?.key ??
                                formData.attributeVariants.sizeScale,
                        },
                        price: formData.prices.sellingPrice,
                        published: formData.published,
                    })
                }
            />

            <Drawer
                visible={!!drawerVisible}
                onClose={() => setDrawerVisible(false)}
                closable={false}
                placement="right"
                className="jc-drawer"
                title="Variant's details"
            >
                <VariantDetails
                    variant={selectedVariant}
                    productId={productId}
                    selectedVariantAvailabilities={selectedVariantAvailabilities}
                    onEditVariant={(formData) => {
                        onEditVariant(selectedVariant, formData, variantGalleryFiles, addedVariantGalleryFiles, deletedVariantGalleryFiles, selectedVariantAvailabilities);
                    }}
                    onGalleryOrderUpdate={(fileList) => { handleGalleryOrderUpdate(fileList) }}
                    onGalleryUpdate={(file, fileList) => { handleGalleryUpdate(file, fileList) }}
                    variantGalleryFiles={variantGalleryFiles}
                    closeDrawer={() => setDrawerVisible(false)}
                />
            </Drawer>
        </div>
    );
};
