import React, {
    createContext,
    useState,
    useCallback,
    useContext,
    useEffect,
} from "react";
import { message } from "src/services/Messages.service";
import { useParams } from "react-router-dom";
import { UploadsAPI } from "src/modules/upload/api/UploadsApi";
import { WarehousesAPI } from "src/modules/warehouses/api/WarehousesApi";
// import { UploadTypes } from "src/modules/upload/domain/models/UploadTypes";

// const { TYPE_KEYS } = UploadTypes;
export const uploadTypeDetailsContext = createContext();

const useProvideUploadTypeDetails = () => {
    const params = useParams();
    // const pageParams = [TYPE_KEYS.CATEGORY, TYPE_KEYS.ATTRIBUTE];
    const [uploadTypeDetails, setUploadTypeDetails] = useState({});
    const [warehousesList, setWarehousesList] = useState([])

    /**
     * Get the details of the selected type using the param - for the moment data is static check the file UploadTypes.js
     * @type {(function(): void)|*}
     */
    const getUploadTypeDetails = useCallback(() => {
        const res = UploadsAPI.getUploadTypeDetails(params.type);
        setUploadTypeDetails(res);
    }, [params.type])

    /**
     * @description Upload file for the selected type - information needed is warehouseId and uploaded file
     * In case of product and stock type warehouseId is warehouseId (warehouse selected - input) and in case of attribute and category type the
     * warehouseId respective values are:
     * category -> warehouseId = category
     * attribute -> warehouseId = attribute;
     * Change the filename in case of stock adding stock_file name
     * @type {(function(*=, *=): Promise<any|undefined>)|*}
     */
    const uploadFile = useCallback(async (warehouseId, file) => {
        try {
            return await UploadsAPI.uploadFile(warehouseId, file);
        } catch (e) {
            return Promise.reject(e);
        }
    }, []);

    /**
     * Get Warehouse List - data needed in case type selected is product or stock
     * @type {(function(): Promise<undefined>)|*}
     */
    const getWarehousesList = useCallback(async () => {
        try {
            const res = await WarehousesAPI.getWarehousesList();
            setWarehousesList(res.data.data.items);
        } catch (e) {
            message.error(e);
        }
    }, []);

    useEffect(() => {
        getUploadTypeDetails()
        // TODO Do the request to get the warehouse data only in case the type is product or stock otherwise not
            getWarehousesList()
    }, [getWarehousesList, getUploadTypeDetails]);

    return {
        uploadTypeDetails,
        uploadFile,
        warehousesList,
    };
};

export const UploadTypeDetailsProvider = ({children}) => {
    const uploadTypeDetails = useProvideUploadTypeDetails();
    return (
        <uploadTypeDetailsContext.Provider value={uploadTypeDetails}>
            {children}
        </uploadTypeDetailsContext.Provider>
    );
};

export const useUploadTypeDetails = () => useContext(uploadTypeDetailsContext);
