import React, {
  useState,
  createContext,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { useParams } from "react-router-dom";
import { useUrlSearch } from "src/hooks/useUrlSearch";
import { VisualMerchandisingAPI } from "../api/VisualMerchandisingApi";
import { message } from "src/services/Messages.service";

export const visualMerchandisingProductsContext = createContext();

const useProvideVisualMerchandisingProducts = () => {
  const search = useUrlSearch();
  const params = useParams();
  const [isUpdatePending, setIsUpdatePending] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [visualMerchandisingItemCount, setVisualMerchandisingItemCount] = useState(0)
  const [pendingGetVisualMerchandisingProducts, setPendingGetVisualMerchandisingProducts] = useState(false)
  const [visualMerchandisingProducts, setVisualMerchandisingProducts] =
    useState([]);
  const [
    filteredVisualMerchandisingProducts,
    setFilteredVisualMerchandisingProducts,
  ] = useState([]);

  /**
   * @description Get visual merchandising products List
   * @type {(function(): Promise<void|undefined>)|*}
   */
  const getVisualMerchandisingProducts = useCallback(async () => {
    setPendingGetVisualMerchandisingProducts(true);
    try {
      const res = await VisualMerchandisingAPI.getVisualMerchandisingProducts(
        search
      );
      setVisualMerchandisingItemCount(res.data.data.result.currentItemCount)
      setVisualMerchandisingProducts(res.data.data.result.items);
      setFilteredVisualMerchandisingProducts(res.data.data.result.items);
      return Promise.resolve();
    } catch (e) {
      message.error(e)
    }
    finally {
      setPendingGetVisualMerchandisingProducts(false)
    }
  }, [search]);

  /**
   * @description Update product catalog order
   * @type {(function(): Promise<void|undefined>)|*}
   */
  const updateProductsOrder = useCallback(async () => {
    setIsUpdatePending(true);
    try {
      const res = await VisualMerchandisingAPI.updateProductsOrder(
        filteredVisualMerchandisingProducts,
        search.filters,
        params
      );
      setUpdateError(null);
      const responseProductData = res.data.products
      setFilteredVisualMerchandisingProducts(visualMerchandisingProducts.sort((a, b) => responseProductData.indexOf(a.id) - responseProductData.indexOf(b.id)));
      return Promise.resolve();
    } catch (e) {
      setUpdateError(e);
      return Promise.reject(e);
    } finally {
      setIsUpdatePending(false);
    }
  }, [filteredVisualMerchandisingProducts, params, search.filters, visualMerchandisingProducts]);

  /**
   * @description Filter products based on ean
   * @param dataIndex
   * @param searchValue
   */
  const onFiltersChange = (dataIndex, searchValue) => {
    const filteredVisualMerchandisingList = visualMerchandisingProducts.filter(
      (product) => {
        return product[dataIndex]
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      }
    );
    setFilteredVisualMerchandisingProducts(filteredVisualMerchandisingList);
  };

  useEffect(() => {
    getVisualMerchandisingProducts();
  }, [search, getVisualMerchandisingProducts]);

  return {
    isUpdatePending,
    updateError,
    filteredVisualMerchandisingProducts,
    setFilteredVisualMerchandisingProducts,
    updateProductsOrder,
    onFiltersChange,
    visualMerchandisingItemCount,
    pendingGetVisualMerchandisingProducts
  };
};

export const VisualMerchandisingProductsProvider = ({ children }) => {
  const visualMerchandisingProducts = useProvideVisualMerchandisingProducts();

  return (
    <visualMerchandisingProductsContext.Provider
      value={visualMerchandisingProducts}
    >
      {children}
    </visualMerchandisingProductsContext.Provider>
  );
};

export const useVisualMerchandisingProducts = () =>
  useContext(visualMerchandisingProductsContext);
