export class StorageService {
  static get KEYS() {
    return {
      USER: "user",
    };
  }

  static getUser() {
    const user = window.localStorage.getItem(StorageService.KEYS.USER);
    return JSON.parse(user);
  }

  static setUser(user) {
    window.localStorage.setItem(StorageService.KEYS.USER, JSON.stringify(user));
  }

  static deleteUser() {
    window.localStorage.removeItem(StorageService.KEYS.USER);
  }
}
