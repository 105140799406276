import React, { createContext, useContext, useState } from "react";
import { AuthAPI } from "src/modules/auth/api/AuthAPI";
import { StorageService } from "src/services/Storage.service";

export const authContext = createContext();

const useProvideAuth = () => {
  const [user, setUser] = useState(StorageService.getUser());

  const signIn = async (data) => {
    try {
      const res = await AuthAPI.login(data);
      StorageService.setUser(res.data.data);
      setUser(res.data.data);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const signOut = () => {
    StorageService.deleteUser();
    setUser(null);
  };

  return {
    user,
    signIn,
    signOut,
  };
};

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => useContext(authContext);
