import { AxiosClient } from "src/api/AxiosClient";

export class GlobalAPI {
  /**
   * @description Get languages
   * @returns {Promise<*>}
   */
  static async getLanguages() {
    return AxiosClient.get("/languages");
  }

  static async getAttributeCodes() {
    return AxiosClient.get("/attributes/distinct-codes");
  }

  static async getAttributes(code, page, limit = 10) {
    const res = await AxiosClient.get("/attributes", {
      params: {
        attributeCode: code,
        pageNumber: page,
        limit,
      },
    });
    if (res.data?.data?.items)
      res.data.data.items = res.data.data.items.map((item) => ({
        key: item.attributeKey,
        label: item.localeName,
        id: item.id,
      }));

    return res;
  }
}
