import React, { useCallback } from "react";
import { Row, Col, Form } from "antd";
import { CategoriesAPI } from "src/modules/categories/api/CategoriesAPI";
import { InputSelectInfinite } from "src/components/inputs/InputSelectInfinite";

export const Categories = ({ form, macroKey, microKey }) => {
  const getMacroCategories = useCallback((page) => {
    return CategoriesAPI.getMacroCategories(page);
  }, []);

  const getMicroCategories = useCallback(
    (page) => {
      return CategoriesAPI.getMicroCategories(macroKey, page);
    },
    [macroKey]
  );

  const getBrands = useCallback(() => {
    return CategoriesAPI.getBrands();
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <Form.Item
          name={["categories", "macro"]}
          label="Category"
          rules={[
            {
              required: true,
              message: `Please insert a category`,
            },
          ]}
        >
          <InputSelectInfinite
            onLoadMore={getMacroCategories}
            placeholder="Select"
            onChange={() => {
              form.setFieldsValue({
                categories: {
                  micro: {
                    key: null,
                    label: null,
                  },
                },
              });
            }}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={["categories", "micro"]}
          label="Sub category"
          shouldUpdate={(prevValues, currValues) =>
            currValues.categories.macro.key
          }
          rules={[
            {
              required: true,
              message: `Please insert a Sub category`,
            },
          ]}
        >
          <InputSelectInfinite
            onLoadMore={macroKey ? getMicroCategories : null}
            placeholder="Select"
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={["categories", "brand"]}
          label="Brand"
          rules={[
            {
              required: true,
              message: `Please insert brand`,
            },
          ]}
        >
          <InputSelectInfinite onLoadMore={getBrands} placeholder="Select" />
        </Form.Item>
      </Col>
    </Row>
  );
};
