import React from 'react'
import { Row, Col, Table, Tag, InputNumber, Button } from "antd";
import { message } from "src/services/Messages.service"
import { useWarehouses } from "src/modules/warehouses/provider/warehouses.provider";
import { useFilterTags } from "src/hooks/useFilterTags";
import { PageTitle } from "src/components/PageTitle"
import { NumericInput } from "src/modules/warehouses/components/NumericInput"
import { SearchInput } from "src/components/inputs/SearchInput";
import { CustomSelect } from "src/components/inputs/CustomSelect";
import { WarehousesQuery } from "src/modules/warehouses/domain/models/WarehousesQuery";
import { WarehousesMapper } from "src/modules/warehouses/domain/Warehouses.mapper";
import {useTableHeight} from "src/hooks/useTableHeight";
import Moment from "react-moment";
import { useDownloadFile } from "src/hooks/useDownloadFile";
import moment from "moment";
import debounce from "lodash/debounce";

const { KEYS } = WarehousesQuery;


export const PageWarehouses = () => {
    const { query, warehouses, pagination, onChangeQuery, updateWarehouseAvailability, warehousesList, setWarehouses, isWarehouseListLoading, filteredWarehousesList, setFilteredWarehousesList } = useWarehouses();
    const searchParameters = ["current", "pageSize"]
    const tableHeight = useTableHeight();
    const filterTags = useFilterTags(query, searchParameters);
    const downloadFile = useDownloadFile({
        onError(err) {
            message.error(
                err.response?.data?.detail || err.response?.data || err.message
            );
        },
    });

    const onWarehouseAvailabilityChange = async (record, availability) => {
        try {
            record.isUpdatePending = true;
            const res = await updateWarehouseAvailability(record, availability)
            setWarehouses(warehouses.map(el => el.id === record.id ? {
                ...el,
                availabilityTotal: res.data.data.availability,
                isUpdatePending: false

            } : el))
            message.success(`You've just updated ${record.manufacturerCode} ${record.warehouse.name}'s availability`);
        } catch (e) {
            message.error(e);
        }
    }

    const columns = [
        {
            title: "",
            dataIndex: ["coverPhotoUrl"],
            width: "5%",
            render: (_, record) => (
                <div className="flex items-center">
                    <img
                        className="w-8 h-8 object-cover object-center"
                        src={record.coverPhotoUrl}
                        alt={record.name}
                    />
                </div>
            ),
        },
        {
            title: "Name",
            width: "25%",
            dataIndex: ["name"],
            editable: true,
            inputType: "text",
        },
        {
            title: "Ean",
            dataIndex: ["manufacturerCode"],
        },
        {
            title: "Price",
            width: "10%",
            dataIndex: ["sellingPrice"],
            align: 'right'
        },
        {
            title: "Warehouse",
            dataIndex: ["warehouse", "name"],
        },
        {
            title: "Last Edit",
            dataIndex: ["updateDate"],
            key: "updateDate",
            defaultSortOrder: "descend",
            sorter: (a, b) =>
                moment(a.updateDate).unix() - moment(b.updateDate).unix(),
            render: (text) => <Moment format="DD MMM YYYY" date={text} />,
        },
        {
            title: "Stock Q.Ty",
            dataIndex: ["availabilityTotal"],
            render: (value, row) => {
                return (
                    <div>
                        <NumericInput value={value} onSubmit={(value) => onWarehouseAvailabilityChange(row, value)} placeholder="Stock Q.Ty" maxLength={25} />
                    </div>
                )
            }
        },
    ];

    const onTableChange = ({ current, pageSize }) => {
        onChangeQuery({ current, pageSize });
    };

    const onTagRemove = (tag) => {
        onChangeQuery({
            ...query,
            [tag.key]: null,
        });
        if (tag.key === KEYS.WAREHOUSE) {
            setFilteredWarehousesList(warehousesList)
        }
    };

    const handleFullTextSearch = (value) => {
        onChangeQuery({
            ...query,
            ean: value,
            current: 1,
        });
    };

    const onChangeFilter = (key, value) => {
        if (key === KEYS.WAREHOUSE && value) {
            setFilteredWarehousesList(warehousesList.filter(warehouse => warehouse.name === value));
        } else if (key === KEYS.WAREHOUSE && !value) {
            setFilteredWarehousesList(warehousesList);
        }
        onChangeQuery({
            ...query,
            [key]: value,
            current: 1,
        });
    };

    const downloadXLS = () => {
        const params = WarehousesMapper.fromQueryToQueryString(query)
        downloadFile.call(`/v2/products/stocks/export/xlsx?${params}`, "warehouse.xlsx")
    }

    return (
        <>
            <div className="py-4 page-filters">
                <PageTitle className="py-4">Warehouse</PageTitle>
                <Row gutter={[16, 16]} className="pt-2">
                    <Col span={6}>
                        <SearchInput
                            id={KEYS.EAN}
                            placeholder="EAN"
                            onChangeText={handleFullTextSearch}
                            defaultValue={query[KEYS.EAN]}
                        />
                    </Col>
                    <Col span={6}>
                        <CustomSelect
                            items={filteredWarehousesList}
                            value={query[KEYS.WAREHOUSE]}
                            onApply={(value) => onChangeFilter(KEYS.WAREHOUSE, value)}
                            onReset={() => onChangeFilter(KEYS.WAREHOUSE, null)}
                            searchProperty="name"
                            keyProperty="name"
                            placeholder="Warehouses"
                            className="m-2"
                        />
                    </Col>
                    <Col span={6}>
                        <InputNumber
                            placeholder="Stock quantity"
                            min={0}
                            onChange={debounce((value) => onChangeFilter(KEYS.AVAILABILITY, value), 500)}
                            value={query[KEYS.AVAILABILITY]}
                        />
                    </Col>
                    <Col span={6}>
                        <div className="flex justify-end">
                            <Button
                                loading={downloadFile.isLoading}
                                onClick={() => downloadXLS()}
                                type="primary"
                                className="uppercase"
                            >
                                Download .xls
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row className="py-4 antIcon">
                    <div className="pr-2">Filter applied:</div>
                    {filterTags.map((tag) => (
                        <Tag className="cursorTag" key={tag.value} closable onClose={() => onTagRemove(tag)} onClick={() => onTagRemove(tag)}>
                            {tag.value}
                        </Tag>
                    ))}
                </Row>
            </div>
            <Table
                size="middle"
                dataSource={warehouses}
                rowKey="id"
                columns={columns}
                scroll={{ y: tableHeight }}
                pagination={pagination}
                onChange={onTableChange}
                loading={isWarehouseListLoading}
                rowClassName={(record) => (record.isUpdatePending ? "opacity-50" : "")}
            />
        </>
    );
};
