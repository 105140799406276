import { AxiosClient } from "src/api/AxiosClient";

export class CategoriesAPI {
  static async getMacroCategories(page, limit = 10) {
    const res = await AxiosClient.get("/categories", {
      params: {
        pageNumber: page,
        limit,
      },
    });

    res.data.data.items = res.data.data.items.map((item) => ({
      key: item.key,
      label: item.locale.name,
    }));

    return res;
  }

  static async getMicroCategories(macroKey, page, limit = 10) {
    const res = await AxiosClient.get("/products/micro-category-list", {
      params: {
        parentKey: macroKey,
        context: "VARIANT",
        pageNumber: page,
        limit,
      },
    });

    res.data.data.items = res.data.data.map((item) => ({
      key: item.key,
      label: item.localeName,
    }));

    return res;
  }

  static async getBrands() {
    const res = await AxiosClient.get("/brands", {
      params: {
        name: "",
      },
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      data: " ", //headers are ignored if data is empty
    });

    res.data.data.items = res.data.data.map((item) => ({
      id: item.id,
      key: item.key,
      label: item.name,
    }));

    return res;
  }
}
