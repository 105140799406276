import React, { createContext, useContext, useEffect, useState } from "react";
import { GlobalAPI } from "src/modules/global/api/GlobalAPI";
import { message } from "src/services/Messages.service";

const attributesCache = {};

export const attributesContext = createContext();

const useProvideAttributes = () => {
  const [attributeCodes, setAttributeCodes] = useState([]);

  const fetchAttributeCodes = async () => {
    try {
      const res = await GlobalAPI.getAttributeCodes();
      setAttributeCodes(res.data.data);
    } catch (e) {
      setAttributeCodes([]);
      message.error(e);
    }
  };

  const getAttributes = async (code, page) => {
    try {
      const res = await GlobalAPI.getAttributes(code, page);
      return res;
    } catch (e) {
      message.error(e);
    }
  };

  const getAttrsForInfiniteSelect = (code, page) => {
    if (attributesCache[code]) {
      return attributesCache[code];
    }
    return getAttributes(code, page);
  };

  const clearAttributesCache = () => {
    Object.keys(attributesCache).forEach((key) => delete attributesCache[key]);
  };

  /* get attribute codes */
  useEffect(() => {
    fetchAttributeCodes();
  }, []);

  return {
    attributeCodes,
    getAttributes,
    getAttrsForInfiniteSelect,
    clearAttributesCache,
  };
};

export const AttributesProvider = ({ children }) => {
  const attributes = useProvideAttributes();
  return (
    <attributesContext.Provider value={attributes}>
      {children}
    </attributesContext.Provider>
  );
};

export const useAttributes = () => useContext(attributesContext);
