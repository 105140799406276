export class UploadTypes {
    static get TYPE_KEYS() {
        return {
            PRODUCT: "product",
            CATEGORY: "category",
            STOCK: "stock",
            ATTRIBUTE: "attribute",
        };
    }
}
