import React, { useMemo } from "react";
import { Tag } from "antd";

const PRODUCT_STATUS_COLOR_MAP = {
  published: "cyan",
  draft: "volcano",
};

export const ProductStatusTag = ({ status }) => {
  const color = useMemo(() => {
    return PRODUCT_STATUS_COLOR_MAP[status];
  }, [status]);

  return <Tag color={color}>{status}</Tag>;
};
