import { AxiosClient } from "src/api/AxiosClient";

export class AuthAPI {
  static login(data) {
    return AxiosClient.post(
      "/login",
      {},
      {
        params: {
          "remember-me": data.remember,
          username: data.email,
          password: data.password,
        },
      }
    );
  }
}
