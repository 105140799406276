import { Filters } from "src/services/Filters.service";
import { FolderHistoryQuery } from "src/modules/upload/domain/models/FolderHistoryQuery";
import { PayloadListAbstract } from 'src/domain/PayloadListAbstract';

export class FolderHistoryMapper {
    static fromQueryToQueryString(query) {
        const search = Filters.removeFalsyValues(query);
        return new URLSearchParams(search).toString();
    }

    static fromQueryStringToQuery(qs) {
        const query = new URLSearchParams(qs);
        const folderHistoryQueryModel = new FolderHistoryQuery();
        folderHistoryQueryModel.current = query.get("current") || 1;
        folderHistoryQueryModel.pageSize = query.get("pageSize") || 10;
        return folderHistoryQueryModel;
    }

    static fromQueryToPayload(query) {
        const payload = new PayloadListAbstract();
        payload.pageNumber = query.current || 1;
        payload.pageSize = query.pageSize || 10;
        return payload.getCleanPayload();
    }
}
