export class ProductDetailsUpdatePayload {

    constructor(data) {
        this.name = data.name;
        this.descriptions = data.descriptions;
        //this.descriptions = Filters.getLocalisedFilterPayload(data.description);
        this.brand = {
            key: data.categories?.brand?.key
        }
        this.stories = data.stories;
        //this.stories = Filters.getLocalisedFilterPayload(data.storytelling)
        this.names = data.names;
        this.notes = data.notes;
        // this.notes = Filters.getLocalisedFilterPayload(data.note)
        this.prices = {
            sellingPrice: data.price
        }
        this.attributeList = data.attributeList.map((attribute) => ({
            attributeCode: attribute.attributeCode || attribute.code,
            key: attribute.value?.key || attribute.key,
            id: attribute.attributeId || attribute.id
        }))
        this.macroCategory = {
            key: data.categories?.macro?.key
        };

        this.microCategory = {
            key: data.categories?.micro?.key
        };
    }
}
