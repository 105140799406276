import React from 'react'
import { useParams } from "react-router";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { AntDFileHelper } from "src/services/AntDFileHelper";
import { RenameFileHelper } from "src/services/RenameFile";

const { Dragger } = Upload;

export const FileUpload = ({
  multiple = false,
  acceptedFileTypes = [],
  fileSize,
  maxCount = 1,
  name = "file",
  uploadedFile,
  onUploadedFileChange,
}) => {

  const params = useParams();
  const handleUploadedFile = (file) => {
    const newFile =
      params.type === "stock" ? RenameFileHelper.renameFile(file, `stock_${file.name}`) : file;
    onUploadedFileChange(newFile);
  };

  const props = {
    name: name,
    multiple: multiple,
    maxCount: maxCount,
    onRemove: () => onUploadedFileChange(null),
    beforeUpload: (file) => {
      const isCorrectFileType = acceptedFileTypes.includes(
        AntDFileHelper.getExtension(file)
      );
      if (!isCorrectFileType) {
        message.error("Check the file type");
        return false;
      }

      // check the file size
      const isCorrectFileSize = AntDFileHelper.getBytes(file) <= fileSize;
      if (!isCorrectFileSize) {
        message.error("Check the file size");
        return false;
      }
      handleUploadedFile(file);
      return false;
    },
    fileList: uploadedFile ? [uploadedFile] : null,
  };
  return (
    <>
      <Dragger {...props}>
        <div className="p-8">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text px-4">
            Click or drag file to this area to upload
          </p>
        </div>
      </Dragger>
    </>
  );
};
