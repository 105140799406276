import { AxiosClient } from "src/api/AxiosClient";
import { UPLOAD_TYPES } from "src/constants/uploadTypes";

export class UploadsAPI {
  /**
   * @desc Get folder history
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getFolderHistory(data) {
    return AxiosClient.get(`/csv`, { params: data });
  }

  /**
   * @description Get Upload type details
   * @param type
   * @returns {{formats: [string], description: string, disabled: boolean, id: number, avatar: string, title: string, max_wight: number, template_link: string, type_key: string}|{formats: [string], description: string, disabled: boolean, id: number, avatar: string, title: string, max_wight: number, template_link: string, type_key: string}|{formats: [string], description: string, disabled: boolean, id: number, avatar: string, title: string, max_wight: number, template_link: string, type_key: string}|{formats: [string], description: string, disabled: boolean, id: number, avatar: string, title: string, max_wight: number, template_link: string, type_key: string}}
   */
  static getUploadTypeDetails(type) {
    return UPLOAD_TYPES.filter((uploadType) => uploadType.type_key === type)[0];
  }

  /**
   * @desc Request on upload file
   * @param warehouseId
   * @param file
   * @returns {Promise<any>}
   */
  static async uploadFile(warehouseId, file) {
    const fmData = new FormData();
    fmData.append("file", file);
    try {
      const { data } = await AxiosClient.post(
        `/csv?folder=${warehouseId}`,
        fmData,
        {
          headers: { "content-type": "multipart/form-data" },
        }
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * @description Get status by UUID
   * @param UUID
   * @returns {Promise<any>}
   */
  static async getStatusByUUID(UUID) {
    const { data } = await AxiosClient.get(`/csv/status/${UUID}`);
    return data;
  }

  /**
   * @description Get Error file by passing UUID
   * @param UUID
   * @returns {Promise<any>}
   */
  static async getErrorFileByUUID(UUID) {
    const { data } = await AxiosClient.get(`/csv/file/${UUID}`, {
      responseType: "blob",
    });
    return data;
  }
}
