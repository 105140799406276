import React from 'react';
import { Typography } from 'antd';
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const {  Link } = Typography;

export const BackButton = ({ title, className }) => {
  const history = useHistory();

  return (
    <div className={className}>
      <Link className="uppercase flex items-center" onClick={ ()=>history.goBack() }> <ArrowLeftOutlined className="pr-1"/> {title}</Link>
    </div>
  )
};
