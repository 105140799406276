import React, {useMemo, useState} from "react";
import { Row, Col, Button } from "antd";
import { useParams } from "react-router-dom";
import { useVisualMerchandisingProducts } from "src/modules/visualMerchandising/provider/visualMerchandisingProducts.provider";
import { BackButton } from "src/components/BackButton";
import { PageTitle } from "src/components/PageTitle";
import { HelperText } from "src/components/forms/HelperText";
import { SearchInput } from "src/components/inputs/SearchInput";
import { SortableTable } from "src/modules/visualMerchandising/components/SortableTable";
import { SortableList } from "src/modules/visualMerchandising/components/SortableList";
import { message } from "src/services/Messages.service";
import { useTableHeight } from "src/hooks/useTableHeight";

export const PageVisualMerchandisingProducts = () => {
    const {
        isUpdatePending,
        filteredVisualMerchandisingProducts,
        setFilteredVisualMerchandisingProducts,
        onFiltersChange,
        updateProductsOrder,
        visualMerchandisingItemCount,
        pendingGetVisualMerchandisingProducts
    } = useVisualMerchandisingProducts();

    const params = useParams();
    const [tableView, setTableView] = useState(true);
    const tableHeight = useTableHeight();

    /**
     * @description
     * @param value: String
     */
    const handleVisualMerchandisingProductsSearch = (value) => {
        onFiltersChange("name", value);
    };

    const handleSave = () => {
        updateProductsOrder().catch((e) => message.error(e))
            ;
    };

    const columns = useMemo(
        () => [
            {
                title: "",
                dataIndex: ["coverPhotoUrl"],
                width: "5%",
                render: (_, record) => (
                    <div className="flex items-center">
                        <img
                            className="w-8 h-8 object-cover object-center"
                            src={record.coverPhotoUrl}
                            alt={record.name}
                        />
                    </div>
                ),
            },
            {
                title: "Name",
                dataIndex: ["name"],
            },
            {
                title: "Color",
                dataIndex: ["attributeVariants", "color"],
                render: (_, record) => record.attributeVariants.color.localeName,
            },
            {
                title: "Category",
                dataIndex: ["macroProductCategory", "key"],
                render: (_, record) => record.macroProductCategory.localeName,
            },
            {
                title: "Sub category",
                dataIndex: ["microProductCategory", "key"],
                render: (_, record) => record.microProductCategory.localeName,
            },
            {
                title: "Price",
                dataIndex: ["currency", "sellingPrice"],
                render: (_, record) => (
                    <div>
                        {record.currency} {record.sellingPrice}
                    </div>
                ),
            },
        ],
        []
    );

    return (
        <>
            <div className="py-4 page-filters">
                <Row className="py-4">
                    <Col span={12}>
                        <BackButton
                            title="Back to Visual Merchandising"
                            className="pb-4 text-sm"
                        />
                        <PageTitle>{params.visualMerchandising}</PageTitle>
                        <HelperText
                            className="pb-4"
                            text={`In this page you can manage the order of the visual's items.`}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 16]} className="pb-4">
                    <Col span={6}>
                        <SearchInput
                            placeholder="Name"
                            onChangeText={handleVisualMerchandisingProductsSearch}
                        />
                    </Col>
                    <Col span={6} offset={12}>
                        <div className="flex justify-end">
                            <Row gutter={[16, 16]}>
                                <Col>
                                    <Button
                                        className="w-full uppercase"
                                        onClick={handleSave}
                                        loading={isUpdatePending}
                                        type="primary"
                                    >
                                        Save
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className="w-full uppercase"
                                        onClick={() => setTableView(!tableView)}
                                    >{`${tableView ? 'List' : 'Table'} View`}</Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            <div>
                {tableView ? <SortableTable
                    isLoading={pendingGetVisualMerchandisingProducts || isUpdatePending}
                    data={filteredVisualMerchandisingProducts}
                    count={visualMerchandisingItemCount}
                    columns={columns}
                    onReorder={setFilteredVisualMerchandisingProducts}
                    scroll={{ y: tableHeight }}
                /> :
                    <SortableList
                        data={filteredVisualMerchandisingProducts}
                        onReorder={setFilteredVisualMerchandisingProducts}
                        isLoading={pendingGetVisualMerchandisingProducts || isUpdatePending}
                    />}
            </div>
        </>
    );
};
