import { AxiosClient } from "src/api/AxiosClient";
import { ProductUpdatePayload } from "src/modules/products/api/payloads/ProductUpdatePayload";
import { ImageModel } from "src/modules/products/domain/models/ImageModel";
import { Product } from "src/services/Product.service";
import { VariantUpdatePayload } from "src/modules/products/api/payloads/VariantUpdatePayload";
import { VariantModel } from "src/modules/products/domain/models/VariantModel";
import { AvailabilityPayload } from "src/modules/products/api/payloads/AvailabilityPayload";
import { ProductDetailsUpdatePayload } from "src/modules/products/api/payloads/ProductDetailsUpdatePayload";

export class ProductsAPI {
  // ----------------------------------PRODUCT CRUD --------------------------------------------
  /**
   * @description Search on Products
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async searchProducts(data) {
    return AxiosClient.post("/backoffice/products/filter", data);
  }

  /**
   * @description Inline editing of a product
   * @param id
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateProduct(id, data) {
    return AxiosClient.put(`/products/${id}`, new ProductUpdatePayload(data))
      .then((res) => res.data.data)
      .then((apiData) => ({
        ...apiData,
        macroProductCategory: {
          ...apiData.macroCategory,
          key: apiData.macroCategory.key,
          label: apiData.macroCategory.localeName,
        },
        microProductCategory: {
          ...apiData.microCategory,
          key: apiData.microCategory.key,
          label: apiData.microCategory.localeName,
        },
      }));
  }

  /**
   * @description Update product - product details page
   * @param productId
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateProductDetails(productId, data) {
    return AxiosClient.put(
        `/products/${productId}`,
        new ProductDetailsUpdatePayload(data)
    )
        .then((res) => res.data.data)
  }

  /**
   * @description Delete product
   * @param productId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async deleteProduct(productId) {
    return AxiosClient.delete(`/products/${productId}`);
  }

  /**
   * @description Get details of a single product
   * @param productId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getProduct(productId) {
    return AxiosClient.get(`/products/${productId}`);
  }

  /**
   * @description Update product status
   * @param productId
   * @param status
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateProductStatus(productId, status) {
    const productStatus = Product.getStatusBooleanValue(status);
    return AxiosClient.put(`/products/${productId}/publish/${productStatus}`, {
      productId: productId,
    });
  }

  static async updateProductGallery(productId, fileList, addedFiles, deletedFiles) {
    const addedFilesPromises = addedFiles.map((file) => {
      const fmData = new FormData();
      fmData.append("fileUpload1", file);
      return AxiosClient.post(
          `/products/${productId}/product-photo/details`,
          fmData,
          {
            headers: {"content-type": "multipart/form-data", "accept": "application/stentle.api-v0.2+json"},
          }
      ).then(({data}) => data.data.photoGallery.allImages.map((img) => new ImageModel(img)));
    });

    const deletedFilesPromises = deletedFiles.map((file) => {
      return AxiosClient.delete(
          `/products/${productId}/product-photo/${file.uid}`,
          {
            headers: {
              accept: "application/stentle.api-v0.2+json",
            },
          }
      ).then(({data}) => data.data.photoGallery.allImages.map((img) => new ImageModel(img)));
    });

    return Promise.all([...addedFilesPromises, ...deletedFilesPromises]);

  }

  // ---------------------------VARIANT CRUD -------------------------------

  /**
   * @description Get product variants
   * @param productId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getProductVariants(productId) {
    return AxiosClient.get(`/products/${productId}/variants`);
  }

  /**
   * @description Delete a single variant
   * @param productId
   * @param variantId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async deleteVariant(productId, variantId) {
    return AxiosClient.delete(`/products/${productId}/variants/${variantId}`);
  }

  /**
   * @description Update variant gallery
   * @param productId
   * @param variantId
   * @param fileList
   * @param addedFiles
   * @param deletedFiles
   * @returns {Promise<unknown[]>}
   */
  static async updateVariantGallery(productId, variantId, fileList, addedFiles, deletedFiles) {
    const addedFilesPromises = addedFiles.map((file) => {
      const fmData = new FormData();
      fmData.append("fileUpload1", file);
      return AxiosClient.post(
          `/products/${productId}/variants/${variantId}/variant-photo/details`,
          fmData,
          {
            headers: {"content-type": "multipart/form-data", "accept": "application/stentle.api-v0.2+json"},
          }
      ).then(({data}) => data.data.photoGallery.allImages.map((img) => new ImageModel(img)));
    });

    const deletedFilesPromises = deletedFiles.map((file) => {
      return AxiosClient.delete(
          `/products/${productId}/variants/${variantId}/variant-photo/${file.uid}`,
          {
            headers: {
              accept: "application/stentle.api-v0.2+json",
            },
          }
      ).then(({data}) => data.data.photoGallery.allImages.map((img) => new ImageModel(img)));
    });

    return Promise.all([...addedFilesPromises, ...deletedFilesPromises]);
  }
  /**
   * @description Get variant availabilities
   * @param productId
   * @param variantId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getVariantAvailabilities(productId, variantId) {
    return AxiosClient.get(
      `/products/${productId}/variants/${variantId}/availabilities`
    );
  }

  /**
   * @description Update variant request
   * @param productId
   * @param variantId
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateVariant(productId, selectedVariant, variantFormData) {
    return AxiosClient.put(
      `/products/${productId}/variants/${selectedVariant.id}`,
      new VariantUpdatePayload(selectedVariant, variantFormData)
    )
      .then((res) => res.data.data)
      .then((apiData) => new VariantModel(apiData));
  }

  /**
   * @description Update Variant Availability
   * @param productId
   * @param variantId
   * @param warehouseAvailabilityId
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateVariantAvailability(
    productId,
    variantId,
    availabilities,
    originalAvailabilities
  ) {
    const mergedAvailabilities = originalAvailabilities.map((a) => {
      const [id, value] =
        Object.entries(availabilities).find(([id, value]) => id === a.id) || [];
      if (!id) return a;
      return { ...a, availability: value };
    });

    const promises = mergedAvailabilities.map((data) => {
      return AxiosClient.put(
        `/products/${productId}/variants/${variantId}/availabilities/${data.id}`,
        new AvailabilityPayload(data, variantId)
      ).then((res) => res.data.data);
    });

    return Promise.all(promises);
  }

  /**
   * @description Update variant status
   * @param productId
   * @param variantId
   * @param status
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateVariantStatus(productId, variantId, status) {
    const productStatus = Product.getStatusBooleanValue(status);
    return AxiosClient.put(`/products/${productId}/variant/${variantId}/publish/${productStatus}`, {
      productId: productId,
    });
  }
}
