import get from "lodash/get";

export class PlaceholderService {
  static getMessage(obj, msg) {
    const regex = /{(.*?)}/g;
    return msg.replaceAll(regex, (replacement) => {
      const parsedReplacement = replacement.substring(
        replacement.indexOf("{") + 1,
        replacement.lastIndexOf("}")
      );
      return get(obj, parsedReplacement);
    });
  }
}
