import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useParams } from "react-router-dom";
import { ProductsAPI } from "src/modules/products/api/ProductsAPI";
import { VariantModel } from "src/modules/products/domain/models/VariantModel";
import { ProductModel } from "src/modules/products/domain/models/ProductModel";
import moment from "moment";
import {get, some} from "lodash";

export const productDetailsContext = createContext();

const initialProduct = {
  prices: {},
  attributeList: [],
  macroCategory: {
    key: null,
  },
  microCategory: {
    key: null,
  },
  brand: { id: null, key: null },
};

const useProvideProductDetails = () => {
  const params = useParams();
  const [product, setProduct] = useState(initialProduct);
  const [productVariants, setProductVariants] = useState([]);
  const [filteredProductVariants, setFilteredProductVariants] = useState([]);

  /**
   * @description Get product details
   * @type {(function(): Promise<void|undefined>)|*}
   */
  const getProductDetails = useCallback(async () => {
    try {
      const res = await ProductsAPI.getProduct(params.id);
      setProduct(new ProductModel(res.data.data));
      return Promise.resolve();
    } catch (e) {
      // @todo handle notification
      return Promise.reject(e);
    }
  }, [params.id]);

  /**
   * @description Get variants
   * @type {(function(): Promise<void|undefined>)|*}
   */
  const getProductVariants = useCallback(async () => {
    try {
      const res = await ProductsAPI.getProductVariants(params.id);
      const variant = res.data.data.map(
        (apiVariant) => new VariantModel(apiVariant)
      );
      setProductVariants(variant);
      setFilteredProductVariants(variant);
      return Promise.resolve();
    } catch (e) {
      // @todo handle notification
      return Promise.reject(e);
    }
  }, [params.id]);

  /**
   * @description On filter change - variant search
   * @param dataIndex
   * @param searchValue
   */
  const onFiltersChange = (lookupList, searchValue) => {
    const filteredProductVariants = productVariants.filter((productVariant) => {
      return some(lookupList, function(key) {
        let cellValue = key === 'updateDate' ? moment(get(productVariant, key)).format('DD MMM YYYY') : get(productVariant, key)
        return (cellValue && cellValue.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      })
    });
    setFilteredProductVariants(filteredProductVariants);
  };

  /**
   * @description Add Product image - gallery section
   * @type {(function(*=, *=): Promise<AxiosResponse<*>|undefined>)|*}
   */
  const addProductGalleryImage = useCallback(async (productId, file) => {
    try {
      return await ProductsAPI.addProductGalleryImage(productId, file);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  /**
   * @description REmove product image - gallery section
   * @type {(function(*=, *=): Promise<AxiosResponse<*>|undefined>)|*}
   */
  const deleteProductGalleryImage = useCallback(async (productId, imageId) => {
    try {
      const res = await ProductsAPI.deleteProductGalleryImage(
        productId,
        imageId
      );
      return Promise.resolve(res);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  /**
   * @description Get variant availabilities
   * @type {(function(*=, *=): Promise<AxiosResponse<*>|undefined>)|*}
   */
  const getVariantAvailabilities = useCallback(async (productId, variantId) => {
    try {
      const res = await ProductsAPI.getVariantAvailabilities(
        productId,
        variantId
      );
      return Promise.resolve(res);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  useEffect(() => {
    getProductDetails();
  }, [params, getProductDetails]);

  /**
   * @description GetVariants on
   */
  useEffect(() => {
    getProductVariants();
  }, [params, getProductVariants]);

  return {
    product,
    filteredProductVariants,
    onFiltersChange,
    addProductGalleryImage,
    deleteProductGalleryImage,
    getVariantAvailabilities,
    setFilteredProductVariants,
    setProduct
  };
};

export const ProductDetailsProvider = ({ children }) => {
  const product = useProvideProductDetails();
  return (
    <productDetailsContext.Provider value={product}>
      {children}
    </productDetailsContext.Provider>
  );
};

export const useProductDetails = () => useContext(productDetailsContext);
