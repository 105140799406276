import React, { useState } from "react";
import { Form, Input, Button, Checkbox, Layout, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Routes } from "src/router/Routes.helper";
import { useHistory } from "react-router-dom";
import { useAuth } from "src/modules/auth/provider/auth.provider";

export const PageLogin = () => {
  const history = useHistory();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await auth.signIn(data);
      return history.push(Routes.PATHS.DASHBOARD);
    } catch (e) {
      form.resetFields();
      console.log(form)

      notification.error({
        message: "Cannot authenticate",
        description: "Check your email and password and try again",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout className="h-full">
      <Layout.Content className="flex items-center justify-center">
        <div className="w-4/5 md:w-1/2 lg:w-1/3 pb-16 pt-10 px-12 bg-white rounded flex flex-col items-center justify-center shadow">
          <img
            className="mb-8 h-8"
            src="https://stentle.com/wp-content/themes/stentle/dist/images/logo-stentle-az.png"
            alt="Stentle"
          />
          <Form
            form={form}
            className="w-full"
            name="normal_login"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
          >
            <Form.Item
              className="mb-"
              name="email"

              rules={[
                { required: true, message: "Please input your email" },
                { type: "email", message: "The email provided is not valid" },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              className="mb-2"
              name="password"
              rules={[
                { required: true, message: "Please input your Password" },
              ]}

            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item className="mb-2">
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Button
                loading={isLoading}
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout.Content>
    </Layout>
  );
};
