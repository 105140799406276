import React, { useEffect, useState } from "react";
import { message } from "src/services/Messages.service";
import { Row, Col, Input, Form, Divider, Button, Select } from "antd";
import { useHistory } from "react-router-dom";
import { useProductDetails } from "src/modules/products/provider/productDetails.provider";
import { useLanguages } from "src/modules/global/provider/languages.provider";
import { BackButton } from "src/components/BackButton";
import { SectionTitle } from "src/components/forms/SectionTitle";
import { HelperText } from "src/components/forms/HelperText";
import { WysiwygEditor } from "src/components/inputs/WysiwygEditor";
import { ProductVariants } from "src/modules/products/components/ProductVariants";
import { ProductAttributeList } from "src/modules/products/components/ProductAttributeList";
import { Categories } from "src/modules/products/components/Categories";
import { ImageGallery } from "src/modules/products/components/ImageGallery";
import { ProductsAPI } from "src/modules/products/api/ProductsAPI";
import { Routes } from "src/router/Routes.helper";
import { Product } from "src/services/Product.service";
import { useAttributes } from "src/hooks/useProvideAttributes";
import { ProductModel } from "src/modules/products/domain/models/ProductModel";
import { TranslatedFields } from "src/services/TranslatedFields.service";

const { Option } = Select;

const initialFormData = {
  name: null,
  description: null,
  storyTelling: null,
  attributeList: [],
  note: null,
  price: null,
  categories: {
    macro: { key: null, label: null },
    micro: { key: null, label: null },
    brand: {
      value: null,
      label: null,
    },
  },
  status: null,
};

export const PageProductDetails = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const {
    product,
    filteredProductVariants,
    setFilteredProductVariants,
    setProduct
  } = useProductDetails();
  const { languages, defaultLanguage, setDefaultLanguage } = useLanguages();
  const [formData, setFormData] = useState(initialFormData);
  const { clearAttributesCache } = useAttributes();
  const [isUpdatePending, setIsUpdatePending] = useState(false);
  const [isDeletePending, setIsDeletePending] = useState(false);
  const [productGalleryFiles, setProductGalleryFiles] = useState([]);
  const [addedProductGalleryFiles, setAddedProductGalleryFiles] = useState([]);
  const [deletedProductGalleryFiles, setDeletedProductGalleryFiles] = useState([]);
  const defLanguage = defaultLanguage.language;

  //Created translated field keys for storing translated values in base of selected language - Check Api body example

  const translatedFields = [{ names: 'name' }, { descriptions: 'description' }, { stories: 'storyTelling' }, { notes: 'note' }]

  useEffect(() => {
    setProductGalleryFiles(product?.photoGallery?.allImages || [])
  }, [product.photoGallery]);

  useEffect(() => {
    if (!defaultLanguage) return;

    form.setFieldsValue({ language: defaultLanguage.label });
  }, [product, form, defaultLanguage]);

  useEffect(() => {
    if (!product) return;
    const newFormData = {
      name: product.names?.[defLanguage]?.value,
      published: Product.getStatus(product.published),
      description: product.descriptions?.[defLanguage]?.value,
      storyTelling: product.stories?.[defLanguage]?.value,
      attributeList: product.attributeList.map((attr, index) => ({
        id: index,
        key: attr.key,
        attributeId: attr?.id,
        attributeCode: attr.attributeCode,
        code: attr.attributeLocaleName ?? '',
        label: attr.localeName ?? '',
      })),
      categories: {
        macro: {
          key: product.macroCategory.key,
          label: product.macroCategory.localeName,
        },
        micro: {
          key: product.microCategory.key,
          label: product.microCategory.localeName,
        },
        brand: {
          id: product.brand?.id,
          key: product.brand.key,
          label: product.brand.name,
        },
      },
      note: product.notes?.[defLanguage]?.value,
      price: product.prices.sellingPrice,
    };
    setFormData(newFormData);
    form.setFieldsValue(newFormData);
  }, [form, product, defLanguage]);

  const handleFieldChange = (_, formState) => {
    setFormData((data) => {
      const copy = { ...data };
      for (let { name, value } of formState) {
        if (name[2]) {
          copy[name[0]][name[1]][name[2]] = value;
        } else if (name[1]) {
          copy[name[0]][name[1]] = value;
        } else {
          copy[name[0]] = value;
        }
      }
      return copy;
    });
  };

  /**
   * @description Delete Product - redirect to products list after the product has been deleted successfully
   * @returns {Promise<void>}
   */
  const handleDeleteProduct = async () => {
    setIsDeletePending(true)
    try {
      await ProductsAPI.deleteProduct(product.id);
      clearAttributesCache();
      // Redirect user to product list page after product delete
      goToProductsListPage();
      message.success("Product deleted successfully");
    } catch (e) {
      message.error(e);
    } finally {
      setIsDeletePending(false)
    }
  };

  /**
   * @description Redirect to products list method
   */
  const goToProductsListPage = () => {
    history.push({ pathname: Routes.PATHS.PRODUCTS });
  };

  /**
   * @description Handle delete image from gallery method
   * @param file
   */
  const handleDeleteProductGalleryFile = (file) => {
    if (addedProductGalleryFiles.find(addedFile => addedFile.uid === file.uid)) {
      setAddedProductGalleryFiles((oldAddedFileList) => oldAddedFileList.filter(addedFile => addedFile.uid !== file.uid))
    }
    if (file.uid.includes('rc-upload')) {
      return;
    }
    setDeletedProductGalleryFiles((oldDeletedFileList) => [...oldDeletedFileList, file])
  }

  /**
   * @description Handle add image to gallery method
   * @param file
   */
  const handleAddedProductGalleryFile = (file) => {
    setAddedProductGalleryFiles((oldAddedProductFileList) => [...oldAddedProductFileList, file])
  }

  /**
   * @description Handle Gallery update
   * @param file
   * @param fileList
   */
  const handleGalleryUpdate = (file, fileList) => {
    setProductGalleryFiles(fileList)
    if (file?.status === "removed") {
      handleDeleteProductGalleryFile(file)
    } else {
      handleAddedProductGalleryFile(file)
    }
  }

  const handleGalleryOrderUpdate = (fileList) => {
    // console.log("Ordered file list", fileList)
  }
  /**
   * @description Form save
   * @param values
   */
  const onSubmit = async (data) => {
    clearAttributesCache();
    setIsUpdatePending(true)
    try {
      //Update gallery
      await ProductsAPI.updateProductGallery(product.id, productGalleryFiles, addedProductGalleryFiles, deletedProductGalleryFiles)
      // Update product status
      await ProductsAPI.updateProductStatus(product.id, data.published);
      // Update product
      // Prepare the structure for fields that need to be translated
      TranslatedFields.translatedField(
          data,
          product,
          translatedFields,
          defLanguage
      );
      let res = await ProductsAPI.updateProductDetails(product.id, data);

      // TODO attributeLocaleName e localeName non campi persistiti sul db e vengono spesso calcolati a runtime - do a request to get again the product detail data
      setProduct(new ProductModel(res))
      message.success("Product updated successfully");
    } catch (e) {
      message.error(e);
    } finally {
      setIsUpdatePending(false);
    }

  };

  const handleTextareaChange = (name, value) => {
    form.setFieldsValue({
      [name]: value,
    });
  };

  const handleVariantUpdate = async (selectedVariant, variantFormData, variantGalleryFiles, addedVariantGalleryFiles, deletedVariantGalleryFiles, selectedVariantAvailabilities) => {
    clearAttributesCache();
    try {
      //Update variant Gallery
      await ProductsAPI.updateVariantGallery(product.id, selectedVariant.id, variantGalleryFiles, addedVariantGalleryFiles, deletedVariantGalleryFiles)
      // Update availabilities
      await ProductsAPI.updateVariantAvailability(
          product.id,
          selectedVariant.id,
          variantFormData.availabilities,
          selectedVariantAvailabilities
      );
      //Update other variant data
      let apiVariant = await ProductsAPI.updateVariant(
          product.id,
          selectedVariant,
          variantFormData
      );
      setFilteredProductVariants((variants) =>
          variants.map((variant, i) => {
            if (variant.id !== selectedVariant.id) return variant;
            return { ...filteredProductVariants[i], ...apiVariant };
          })
      );
      message.success("Variant updated successfully");
    } catch (e) {
      message.error(e);
    }
  };
  const handleLanguageChange = (language, defaultLanguageObj) => {
    setDefaultLanguage({ language: defaultLanguageObj.key, defaultLanguage: true, label: defaultLanguageObj.value })


  }
  const handleQuickVariantUpdate = async (selectedVariant, variantFormData) => {
    try {
      //Update product status
      await ProductsAPI.updateVariantStatus(product.id, selectedVariant.id, variantFormData.published);
      //Update other variant data
      let apiVariant = await ProductsAPI.updateVariant(
          product.id,
          selectedVariant,
          variantFormData
      );
      setFilteredProductVariants((variants) =>
          variants.map((variant, i) => {
            if (variant.id !== selectedVariant.id) return variant;
            return { ...filteredProductVariants[i], ...apiVariant };
          })
      );
      message.success("Variant updated successfully");
    } catch (e) {
      message.error(e);
    }
  }

  return (
      <div className="py-4  rowHoverCursorStyle" >
        <BackButton
            title="Back to products"
            className="pb-4 text-sm"
        ></BackButton>
        {/*Product Information Section*/}
        <Form
            onFinish={onSubmit}
            form={form}
            onFieldsChange={handleFieldChange}
            initialValues={initialFormData}
            layout="vertical"
            autoComplete="off"
            scrollToFirstError
        >
          {/*Product Name*/}
          <div className="text-lg">{product.name}</div>
          <HelperText text="In this page you can manage all product's details"></HelperText>

          {/*Language*/}
          <Row gutter={[16, 16]} className="pt-8">
            <Col span={8}>
              <Form.Item name="language" label="Language">
                <Select
                    // labelInValue
                    onChange={handleLanguageChange}
                >
                  {languages.map((language) => (
                      <Option key={language.language} value={language.label}>
                        {language.label}
                      </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/*Name*/}
          <Row gutter={[16, 16]} className="pt-4">
            <Col span={8}>
              <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Please input your Name!" }]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            {/*Published*/}
            <Col span={8}>
              <Form.Item name="published" label="Status">
                <Select>
                  {Product.statusOptions.map((status, i) => (
                      <Option key={`${status}-${i}`} value={status}>
                        {status}
                      </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="pt-4">
            {/*Description*/}
            <Col span={8}>
              <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    { required: true, message: "Please input your Description!" },
                  ]}
              >
                <WysiwygEditor
                    initialValue={product.descriptions?.[defLanguage]?.value}
                    name="description"
                    onChange={handleTextareaChange}
                />
              </Form.Item>
            </Col>
            {/*StoryTelling*/}
            <Col span={8}>
              <Form.Item name="storyTelling" label="StoryTelling">
                <WysiwygEditor
                    initialValue={product.stories?.[defLanguage]?.value}
                    name="storyTelling"
                    onChange={handleTextareaChange}
                />
                {/*<TinyEditor*/}
                {/*  initalValue={product.storyTelling}*/}
                {/*  name="storytelling"*/}
                {/*  onChange={handleTextareaChange}*/}
                {/*/>*/}
              </Form.Item>
            </Col>
            {/*Notes*/}
            <Col span={8} >
              <Form.Item name="note" label="Notes">
                <WysiwygEditor
                    initialValue={product.notes?.[defLanguage]?.value}
                    name="note"
                    onChange={handleTextareaChange}
                />
              </Form.Item>
            </Col>
          </Row>

          {/*Variants*/}
          <Divider />
          <SectionTitle title="Variants" className="pb-4" />
          <HelperText text="You can see and edit the variants associated to your product." />
          <ProductVariants
              variants={filteredProductVariants}
              productId={product.id}
              onDeleteVariant={() => { }}
              onQuickEditVariant={handleQuickVariantUpdate}
              onEditVariant={handleVariantUpdate}
          />

          {/*Prices*/}
          <Divider />
          <SectionTitle title="Prices" className="pb-4"></SectionTitle>
          <Row gutter={[16, 16]} className="pt-4">
            <Col span={8}>
              <Form.Item
                  name="price"
                  label="Basic price"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(new Error('Please input a price'));
                        } else if (value && getFieldValue('price') < 0) {
                          return Promise.reject(new Error('Price must be greater than zero'));
                        } else return Promise.resolve();
                      },
                    })]}
              >
                <Input
                    min="0"
                    step="0.1"
                    addonBefore={product.prices?.currency?.symbol}
                    placeholder="Insert Price"
                    className="w-full"
                />
              </Form.Item>
            </Col>
          </Row>

          {/*Gallery*/}
          <Divider />
          <SectionTitle title="Gallery" className="pb-4"></SectionTitle>
          <HelperText text="You can use drag & drop to reorder images"></HelperText>
          {/*<ImageGallery/>*/}
          {product.photoGallery && (
              <ImageGallery
                  fileList={productGalleryFiles}
                  onGalleryUpdate={(file, fileList) => handleGalleryUpdate(file, fileList)}
                  onGalleryOrderUpdate={(fileList) => handleGalleryOrderUpdate(fileList)}
              />
          )}
          {/*Categories*/}
          <Divider />
          <SectionTitle title="Categories" className="pb-4"></SectionTitle>
          {formData.categories.macro?.key && (
              <Categories
                  form={form}
                  macroKey={formData.categories.macro.key}
                  microKey={formData.categories.micro?.key}
              />
          )}

          {/*Attributes*/}
          <Divider />
          <SectionTitle title="Attributes" className="pb-4"></SectionTitle>
          <Form.Item name="attributeList">
            <ProductAttributeList />
          </Form.Item>
          <Divider />
          {/*Delete Product*/}
          <Button
              type="danger"
              htmlType="button"
              className="uppercase mr-2"
              loading={isDeletePending}
              onClick={handleDeleteProduct}
          >
            Delete Product
          </Button>
          <Button type="primary" className="uppercase" htmlType="submit" loading={isUpdatePending}>
            Update Product
          </Button>
        </Form>
      </div>
  );
};
