import React, { useState, useCallback, useEffect } from "react";
import { Button, Radio, Space } from "antd";
import { InputMultiSelectSearch } from "src/components/inputs/InputMultiSelectSearch";
import { SectionTitle } from "src/components/forms/SectionTitle";
import { ProductsQuery } from "src/modules/products/domain/models/ProductsQuery";
import { ProductsListPayload } from "src/modules/products/api/payloads/ProductsListPayload";
import { Product } from "src/services/Product.service";

const { API_FILTERS_KEYS } = ProductsListPayload;
const { KEYS } = ProductsQuery;

export const ProductSideFilters = ({
  currentFilters,
  availableFilters,
  onApply,
}) => {
  const [filters, setFilters] = useState({currentFilters});
  useEffect(() => {
    setFilters({...currentFilters});
  }, [currentFilters]);

  const onChangeFilter = (key, values) => {
    setFilters({
      ...filters,
      [key]: values,
    });
  };

  const onResetFilters = useCallback(() => {
    const resetFilters = {
      ...filters,
      brand: null,
      microCategory: null,
      macroCategory: null,
      published: null,
    };
    setFilters(resetFilters);
    onApply(resetFilters);
  }, [filters, onApply]);

  const applyFilters = () => {
    onApply(filters, true);
  };

  return (
    <>
      <div className="jc-drawer__content">
        {/* Macro Service */}

        <SectionTitle title="Macro category"></SectionTitle>
        <InputMultiSelectSearch
          className="mb-4"
          items={availableFilters.filters[API_FILTERS_KEYS.MACRO_CATEGORY]}
          values={filters.macroCategory}
          onChange={(values) => onChangeFilter(KEYS.MACRO_CATEGORY, values)}
          placeholder="Macro Category"
        />

        {/* Micro Service */}
        <SectionTitle title="Micro category"></SectionTitle>
        <InputMultiSelectSearch
          className="mb-4"
          items={availableFilters.filters[API_FILTERS_KEYS.MICRO_CATEGORY]}
          values={filters.microCategory}
          onChange={(values) => onChangeFilter(KEYS.MICRO_CATEGORY, values)}
          placeholder="Micro Category"
        />

        {/* Brand*/}
        <SectionTitle title="Brand"></SectionTitle>
        <InputMultiSelectSearch
          className="mb-4"
          items={availableFilters.filters.brand}
          values={filters.brand}
          onChange={(values) => onChangeFilter(KEYS.BRAND, values)}
          placeholder="Brand"
          labelProperty="key"
        />

        {/*Status*/}
        <SectionTitle title="Status"></SectionTitle>
        <Radio.Group
          onChange={(e) => onChangeFilter(KEYS.PUBLISHED, e.target.value)}
          value={filters.published}
        >
          <Space direction="vertical">
            {Product.statusOptions.map((status) => {
              return (
                <Radio key={status} value={status}>
                  {status}
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
      </div>
      <div className="jc-drawer__footer flex justify-end">
        <Button type="text" className="mr-2 uppercase" onClick={onResetFilters}>
          clear all
        </Button>
        <Button className="uppercase" type="primary" onClick={applyFilters}>
          apply
        </Button>
      </div>
    </>
  );
};
