import { PRODUCT_STATUS } from 'src/constants/productStatus'

export class Product {
    static statusOptions = Object.values(PRODUCT_STATUS)

    static getStatusBooleanValue = (status) => {
        return status ? PRODUCT_STATUS.PUBLISHED === status : status
    }

    static getStatus(status) {
        switch(status) {
            case true: return PRODUCT_STATUS.PUBLISHED;
            case false: return  PRODUCT_STATUS.DRAFT;
            default: return status;
        }
    }

    static stringToBoolean(string) {
        switch(string.toLowerCase().trim()){
            case "true": return true;
            case "false": return false;
            default: return Boolean(string);
        }
    }


}
