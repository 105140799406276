import React, { useCallback } from "react";
import { ProductAttribute } from "src/modules/products/components/ProductAttribute";
import { Button } from "antd";

export const ProductAttributeList = ({ value, onChange }) => {
  const onChangeAttribute = (changedAttribute) => {
    const updatedAttributes = value.map((prevAttribute) => {
      if (prevAttribute.id !== changedAttribute.id) return prevAttribute;
      //changed first Select value
      if (prevAttribute.code !== changedAttribute.code) {
        //reset second Select value
        return { ...changedAttribute, label: null, value: null };
      }
      //changed second Select value
      return changedAttribute.value?.label
        ? // update second Select label if label exists
          { ...changedAttribute, label: changedAttribute.value?.label }
        : changedAttribute;
    });
    onChange(updatedAttributes);
  };

  const onDelete = useCallback(
    (attr) => {
      onChange(value.filter(({ id }) => id !== attr.id));
    },
    [onChange, value]
  );

  const onAddAttribute = useCallback(() => {
    onChange([
      ...value,
      {
        id: (value?.at(-1)?.id || 0 ) + 1,
        key: null,
        code: null,
        label: null,
      },
    ]);
  }, [onChange, value]);

  return (
    <div>
      {value.map((attribute) => (
        <ProductAttribute
          key={attribute.id}
          className="pt-4 pb-4 border-b border-dashed"
          value={attribute}
          onDelete={() => onDelete(attribute)}
          onChange={onChangeAttribute}
        />
      ))}
      <div className="pt-4">
        <Button type="primary" className="uppercase" onClick={onAddAttribute}>
          Add new Attribute
        </Button>
      </div>
    </div>
  );
};
