import React, {
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import { Row, Col, Button, Drawer, Tag } from "antd";
import { Link } from "react-router-dom";
import { useProducts } from "src/modules/products/provider/products.provider";
import { useFilterTags } from "src/hooks/useFilterTags";
import { useDownloadFile } from "src/hooks/useDownloadFile";
import { ProductSideFilters } from "src/modules/products/components/ProductSideFilters";
import { SearchInput } from "src/components/inputs/SearchInput";
import { ProductStatusTag } from "src/modules/products/components/ProductStatusTag";
import { EditableTable } from "src/modules/products/components/EditableTable";
import { CustomMultiselect } from "src/components/inputs/CustomMultiselect";
import { PageTitle } from "src/components/PageTitle";
import { ProductsListPayload } from "src/modules/products/api/payloads/ProductsListPayload";
import { ProductsQuery } from "src/modules/products/domain/models/ProductsQuery";
import { Routes } from "src/router/Routes.helper";
import { ProductsAPI } from "src/modules/products/api/ProductsAPI";
import { Product } from "src/services/Product.service";
import { message } from "src/services/Messages.service";
import Moment from "react-moment";
import moment from "moment";
import { CategoriesAPI } from "src/modules/categories/api/CategoriesAPI";
import { TranslatedFields } from "src/services/TranslatedFields.service"
import { useLanguages } from "src/modules/global/provider/languages.provider";
const { API_FILTERS_KEYS } = ProductsListPayload;
const { KEYS } = ProductsQuery;

export const PageProducts = () => {
  const header = useRef();
  const { defaultLanguage } = useLanguages();
  const downloadFile = useDownloadFile({
    onError(err) {
      message.error(
        err.response?.data?.detail || err.response?.data || err.message
      );
    },
  });
  const {
    query,
    products,
    availableFilters,
    pagination,
    onChangeQuery,
    setProducts,
    pendingGetProducts,
    getProduct
  } = useProducts();

  const defLanguage = defaultLanguage.language;
  const translatedFields = [{ names: "name" }];
  const searchParameters = ["current", "pageSize"];
  const filterTags = useFilterTags(
    query,
    searchParameters,
    availableFilters?.filters || {}
  );
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editedMacroKey, setEditedMacroKey] = useState("");
  const [completeProduct, setCompleteProduct] = useState({});

  const getMacroCategories = useCallback((page) => {
    return CategoriesAPI.getMacroCategories(page);
  }, []);

  const getMicroCategories = useCallback((page, macroKey) => {
    return CategoriesAPI.getMicroCategories(macroKey, page);
  }, []);

  /**
   * @description  Handle all the columns being displayed on the product table
   * @type {[{dataIndex: string[], width: string, title: string, render: (function(*, *))}, {dataIndex: string[], editable: boolean, width: string, inputType: string, title: string, render: (function(*, *))}, {inputOptions, dataIndex: string[], editable: boolean, inputType: string, title: string, render: (function(*, *): *)}, {inputOptions, dataIndex: string[], editable: boolean, inputType: string, title: string, render: (function(*, *): *)}, {inputOptions, dataIndex: string[], inputType: string, title: string, render: (function(*))}, null, null]}
   */
  const columns = useMemo(
    () => [
      {
        title: "",
        dataIndex: ["coverPhotoUrl"],
        width: "5%",
        render: (_, record) => (
          <div className="flex items-center">
            <img
              className="w-8 h-8 object-cover object-center"
              src={record.coverPhotoUrl}
              alt={record.name}
            />
          </div>
        ),
      },
      {
        title: "Name",
        dataIndex: ["name"],
        editable: true,
        inputType: "text",
        width: "20%",
        render: (_, record) => (
          <Link
            to={Routes.parseRouteParams(Routes.PATHS.PRODUCTS_DETAILS, {
              id: record.id,
            })}
          >
            <p className="line-clamp-1 m-0">{record.name}</p>
          </Link>
        ),
      },
      {
        title: "Category",
        width: "15%",
        dataIndex: ["macroProductCategory"],
        render: (_, record) => record.macroProductCategory.label,
        editable: true,
        inputType: "infinite-select-search",
        onLoadMore: getMacroCategories,
        onChange: (macro, _, form) => {
          setEditedMacroKey(macro.key);
          form.setFieldsValue({
            microProductCategory: { key: null, label: null },
          });
        },
        onMount: (macro) => setEditedMacroKey(macro.key),
      },
      {
        title: "Sub category",
        width: "15%",
        dataIndex: ["microProductCategory"],
        render: (_, record) => record.microProductCategory.label,
        editable: true,
        inputType: "infinite-select-search",
        onLoadMore: editedMacroKey
          ? (page) => getMicroCategories(page, editedMacroKey)
          : null,
      },
      {
        title: "Status",
        width: "10%",
        dataIndex: ["published"],
        render: (status) => <ProductStatusTag status={status} />,
        inputType: "published-select",
        editable: true,
        inputOptions: Product.statusOptions,
      },
      {
        title: "Variants",
        dataIndex: "variantsNumber",
        width: "10%",
        align: "right",
      },
      // TODO Replace the creation date with update date -> For the moment is binded the creation date
      {
        title: "Last Edit",
        width: "15%",
        dataIndex: ["updateDate"],
        key: "updateDate",
        defaultSortOrder: "descend",
        sorter: (a, b) =>
          moment(a.updateDate).unix() - moment(b.updateDate).unix(),
        render: (text) => <Moment format="DD MMM YYYY" date={text} />,
      },
    ],
    [getMacroCategories, getMicroCategories, editedMacroKey]
  );

  /**
   * @description This method handle the table data in case user changes the page or change the total items displayed on that page
   * @param {{
   *  current: string
   *  pageSize: number
   * }} config
   * @returns {string}
   */
  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  /**
   * @description This method handle the click on apply filter button inside the drawer. In the drawer are being added all the filters including even category and subcategory (apart ean)
   * @param filters
   * @param closeDrawer
   */
  const onApplyFilters = (filters, closeDrawer = false) => {
    onChangeQuery({
      ...filters,
      published: Product.getStatusBooleanValue(filters.published),
      current: 1,
    });
    closeDrawer && setDrawerVisible(false);
  };

  /**
   * This method is being called when are being applied the multiselect filter (category and subcategory) - Check the buttons inside the multiselect dropdown
   * @param key
   * @param values
   */
  const onChangeFilter = (key, values) => {
    onChangeQuery({
      ...query,
      [key]: values,
      current: 1,
    });
  };

  /**
   * @description This method handle the remove of applied filters on Filters applied section - On the click of 'X' button this method is being called
   * @param tag
   */
  const onTagRemove = (tag) => {
    if (Array.isArray(query[tag.key])) {
      const updatedValues = query[tag.key].filter(
        (value) => tag.value !== value
      );
      onChangeQuery({
        ...query,
        [tag.key]: updatedValues.length ? updatedValues : null,
      });
    } else {
      onChangeQuery({
        ...query,
        [tag.key]: null,
      });
    }
  };

  /**
   * @description This method handles delete product - Inline delete - check table actions
   * @param record
   * @returns {Promise<void>}
   */
  const handleDelete = async (record) => {
    try {
      await ProductsAPI.deleteProduct(record.id);
      //TODO Change the delete with success message in base of client requirements
      message.success("Product deleted successfully");
    } catch (e) {
      message.error(e);
    }
  };

  const handleStartEditing = async (id) => {
    let res = await getProduct(id);
    setCompleteProduct(res.data.data);
  }

  /**
   * @description Handle the inline update
   * @param form
   * @param recordID
   * @returns {Promise<void>}
   */
  const handleUpdate = async (form, record) => {
    // Add also brand when update inline
    const payload = {
      ...completeProduct,
      ...form,
    };



    TranslatedFields.translatedField(
        payload,
        completeProduct,
        translatedFields,
        defLanguage
    );

    console.log("payload", payload);
    try {
      setProducts((products) =>
        products.map((product) => {
          if (product.id !== record.id) return product;
          return { ...record, ...form, isUpdatePending: true };
        })
      )
      await ProductsAPI.updateProductStatus(record.id, form.published);
      const updatedRecord = await ProductsAPI.updateProduct(record.id, payload);
      setProducts((products) =>
        products.map((product) => {
          if (product.id !== updatedRecord.id) return product;
          return {
            ...product,
            ...updatedRecord,
            published: Product.getStatus(updatedRecord.published),
            isUpdatePending: false,
          };
        })
      );
      message.success("Product updated successfully");
    } catch (e) {
      message.error(e);
    }
  };

  /**
   * @description Full search - for the moment handles only ean and it is not an autocomplete
   * @param value
   */
  const handleFullTextSearch = (value) => {
    onChangeQuery({
      ...query,
      text: value,
      current: 1,
    });
  };

  return (
      <>
      <div ref={header} className="py-4 page-filters">
        <PageTitle>Products</PageTitle>
        <Row gutter={[16, 16]} className="pt-2">
          {/*EAN Search*/}
          <Col span={6}>
            <SearchInput
              placeholder="EAN"
              onChangeText={handleFullTextSearch}
              defaultValue={query.text}
            />
          </Col>

          {/*Macrocategory*/}
          <Col span={6}>
            <CustomMultiselect
              items={availableFilters.filters[API_FILTERS_KEYS.MACRO_CATEGORY]}
              values={query[KEYS.MACRO_CATEGORY]}
              onApply={(values) => onChangeFilter(KEYS.MACRO_CATEGORY, values)}
              onReset={() => onChangeFilter(KEYS.MACRO_CATEGORY, null)}
              placeholder="Category"
              className="m-2"
              keyProperty="key"
              labelProperty="value"
            />
          </Col>

          {/*Microcategory*/}
          <Col span={6}>
            <CustomMultiselect
              items={availableFilters.filters[API_FILTERS_KEYS.MICRO_CATEGORY]}
              values={query[KEYS.MICRO_CATEGORY]}
              onApply={(values) => onChangeFilter(KEYS.MICRO_CATEGORY, values)}
              onReset={() => onChangeFilter(KEYS.MICRO_CATEGORY, null)}
              placeholder="Subcategory"
              className="m-2"
            />
          </Col>
          <Col span={6}>
            <div className="flex justify-between">
              <Button onClick={() => setDrawerVisible(true)}>
                All filters
              </Button>
              <Button
                loading={downloadFile.isLoading}
                onClick={() =>
                  downloadFile.call("/backoffice/export", "catalog.xlsx")
                }
                type="primary"
                className="uppercase"
              >
                Download .xls
              </Button>
            </div>
          </Col>
        </Row>
        <Row gutter={[0, 12]} className="py-4  antIcon">
          <div className="pr-2">Filter applied:</div>
          {filterTags.map((tag, i) => (
            <Tag
              className="cursorTag"
              key={`${tag.key}-${tag.label}-${i}`}
              closable
              onClose={() => onTagRemove(tag)}
              onClick={() => onTagRemove(tag)}
            >
              {tag.key === "published"
                ? Product.getStatus(tag.label)
                : tag.label ?? tag.value}
            </Tag>
          ))}
        </Row>
      </div>

      <EditableTable
        loading={pendingGetProducts}
        tableData={products}
        columns={columns}
        pagination={pagination}
        onStartEdit={handleStartEditing}
        onTableChange={onTableChange}
        onDelete={handleDelete}
        onUpdateRecord={handleUpdate}
        deleteMessage={"You have just deleted {name} product"}
      />

      <Drawer
        visible={!!drawerVisible}
        onClose={() => setDrawerVisible(false)}
        closable={false}
        placement="right"
        className="jc-drawer"
        title="Add filters"
      >
        <ProductSideFilters
          currentFilters={query}
          availableFilters={availableFilters}
          onApply={onApplyFilters}
        />
      </Drawer>
    </>
  );
};
