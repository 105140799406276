export class WarehousesQuery {
    ean;
    current;
    pageSize;
    warehouse;
    availability;

    static get KEYS() {
        return {
            EAN: "ean",
            WAREHOUSE: "warehouse",
            AVAILABILITY: "availability",
        };
    }
}
