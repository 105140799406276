import { Select, Button } from "antd";
import React, { useState, useEffect, createRef, useCallback } from "react";
const { Option } = Select;

export const CustomSelect = ({
  items,
  value,
  placeholder,
  className,
  onReset,
  onApply,
  keyProperty
}) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const [visible, setVisible] = useState(false);
  const dropdownRef = createRef();

  const onResetFilter = () => {
    onReset();
    setSelectedValue(null)
    setVisible(false);
  };

  const onApplyFilter = (selectedValue) => {
    onApply(selectedValue);
    setVisible(false);
  };

  const clickOutside = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSelectedValue(value);
        setVisible(false);
      }
    },
    [value, dropdownRef]
  );

  useEffect(() => {
    if (dropdownRef.current) {
      document.addEventListener("mousedown", clickOutside);
    }
    return () => document.removeEventListener("mousedown", clickOutside);
  }, [dropdownRef, clickOutside]);

  return (

    <Select
      value={value}
      open={visible}
      onFocus={() => setVisible(true)}
      onSelect={(value) => setSelectedValue(value)}
      className="w-full multi-select-dropdown"
      placeholder={placeholder}
      onDropdownVisibleChange={() => setVisible(!visible)}
      dropdownRender={(menu) => (
        <div className={`multi-select ${className || ""}`} ref={dropdownRef}>
          <div onClick={
            () => {
              setVisible(true)
            }
          }>{menu}</div>
          {/* Actions */}
          <div className="flex justify-end pt-2">
            <Button type="text" className="mr-2 uppercase" onClick={onResetFilter}>
              Clear
            </Button>
            <Button
              className="uppercase"
              type="primary"
              onClick={() => onApplyFilter(selectedValue)}
            >
              Apply
            </Button>
          </div>
        </div>
      )}
    >
      {items.map((item) => (
            <Option value={item[keyProperty]} key={item.id}>
            {item[keyProperty]}
            </Option>
          ))}
    </Select>
  );
};
