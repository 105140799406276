import { Filters } from "src/services/Filters.service";
import { WarehousesQuery } from "src/modules/warehouses/domain/models/WarehousesQuery";
import { WarehousesListPayload } from "src/modules/warehouses/api/payloads/WarehousesListPayload";

export class WarehousesMapper {
    static fromQueryToQueryString(query) {
        const search = Filters.removeFalsyValues(query);
        return new URLSearchParams(search).toString();
    }

    static fromQueryStringToQuery(qs) {
        const { KEYS } = WarehousesQuery;
        const query = new URLSearchParams(qs);
        const warehousesQueryModel = new WarehousesQuery();

        warehousesQueryModel.ean = query.get("ean") || undefined;
        warehousesQueryModel.current = query.get("current") || 1;
        warehousesQueryModel.pageSize = query.get("pageSize") || 10;
        warehousesQueryModel.warehouse = query.get(KEYS.WAREHOUSE) || undefined;
        warehousesQueryModel.availability = query.get(KEYS.AVAILABILITY) || undefined;
        return warehousesQueryModel;
    }

    static fromQueryToPayload(query) {
        const { API_FILTERS_KEYS } = WarehousesListPayload;
        const payload = new WarehousesListPayload();

        payload.ean = query.ean;
        payload.pageNumber = query.current || 1;
        payload.limit = query.pageSize || 10;
        payload[API_FILTERS_KEYS.WAREHOUSE] = query.warehouse;
        payload[API_FILTERS_KEYS.AVAILABILITY] = query.availability;
        return payload.getCleanPayload();
    }
}
