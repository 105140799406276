import React, { useState } from "react";
import { InputSelectInfinite } from "./InputSelectInfinite";
import { SearchInput } from "./SearchInput";

export const InputSelectInfiniteWithSearch = (props) => {
  const [search, setSearch] = useState("");

  const searchField = (menu) => (
    <div className="pl-2 pr-2">
      <SearchInput
        className="mb-4"
        placeholder="Search"
        value={search}
        onChangeText={setSearch}
      />
      {menu}
    </div>
  );

  return (
    <InputSelectInfinite
      search={search}
      dropdownRender={searchField}
      {...props}
    />
  );
};
