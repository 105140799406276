import React from 'react'
import { Button, notification } from "antd";

export const useUndoNotification = (duration, onClose) => {
  // @todo antd notification reactivity issue
  let requestDeleted;
  const undo = (key) => {
    requestDeleted = true;
    notification.close(key);
    requestDeleted = false;
  };

  const handleClose = (record) => {
    if (!requestDeleted) {
      onClose(record);
    }
  };

  return (record, opts) => {
    const key = `open${Date.now()}`;

    const btn = (
      <Button type="link" size="small" onClick={() => undo(key)}>
        <div className="text-red-500">Undo</div>
      </Button>
    );
    notification.open({
      placement: "bottomLeft",
      message: "",
      ...opts,
      btn,
      key,
      duration: duration / 1000,
      type: 'error',
      onClose: () => handleClose(record),
    });
  };
};
